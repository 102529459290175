import React, { useState, useRef, useEffect, useImperativeHandle, forwardRef } from "react";
import {
  fetch_healthlocker_file_url,
  soft_number_logger,
  agent_call_initiate,
  agent_transfer_call,
  agent_call_disconnect,
  updateMainMembershipNo,
  updateMobileNo,
  fetch_members_notes,
  service_call_tm_history,
  save_members_notes,
  tm_fetch_soft_number,
  fetchDoctorList,
  fetchDoctorSlots,
  tm_direct_agent_call_disconnect,
  bookMemberAppointment,
  memberDetailsEdge,
  cancel_or_reschedule_appointment,
  service_refresh_member_data,
  fetch_healthlocker_files_v2,
  service_direct_call_disposition,
  upload_edge_file
} from "../../../services/met/Service";
import { Tooltip } from 'react-bootstrap';
import { useSelector, useDispatch } from "react-redux";
import { setWebexCall } from "../../../store/webex/agentWebexSlice";
import TeleHealthBook from "../../cn/components/TeleHealthBook/TeleHealthBook";
import { calculateAge } from "../../utils/Utils";
import { Modal as BsModal, OverlayTrigger } from "react-bootstrap";
import DataTable from "react-data-table-component";


const CallModal = forwardRef(({ singleUserDataList, setSingleUserDataList, callingAgent, setIsLoading, reflect, setReflect }, ref) => {

  const agentWebex = useSelector(state => state.agentWebex);
  const { webexCalling, webexLine, webexCall, webexTransferCall } = agentWebex;

  const dispatch = useDispatch();

  const callModalRef = useRef();
  const callAudio = useRef();
  const ringtoneAudio = useRef(null);
  const transferAudio = useRef(null);
  const isAltNoChangedByUser = useRef(true);
  const closeMockModal = useRef(null);
  const mobileEditInput = useRef(null);
  const conditionModalCloseBtn = useRef(null);
  const [callModal, setCallModal] = useState(null);

  const [softCallEnabled, setSoftCallEnabled] = useState("Y");
  const [callStatus, setCallStatus] = useState("idle");
  const [showteleHealthModal, setShowTeleHealthModal] = useState(false);
  const [rescheduleAppointment, setRescheduleAppointment] = useState("");

  const [
    showCheckMicrophonePermissionModal,
    setShowCheckMicrophonePermissionModal,
  ] = useState(false);
  const [isCallPlaced, setIsCallPlaced] = useState(false);
  const [displayCallStatus, setDisplayCallStatus] = useState("Calling");
  const [callInfoMessaage, setCallInfoMessaage] = useState("");
  const [isCallConnected, setIsCallConnected] = useState(false);
  const [callReportAction, setCallReportAction] = useState("");
  const [singleUserData, setSingleUserData] = useState({});
  const [universalConfNumber, setUniversalConfNumber] = useState(null);
  const [conferenceNumber, setConferenceNumber] = useState(null);
  const [webexLocalMediaStream, setWebexLocalMediaStream] = useState(null);
  const [isMuteDisabled, setIsMuteDisabled] = useState(false);
  const [isHoldDisabled, setIsHoldDisabled] = useState(true);
  const [showDispositionModal, setShowDispositionModal] = useState(false);
  const [isCallDisabled, setIsCallDisabled] = useState(true);
  const [isDiconnectDisabled, setIsDisconnectDisabled] = useState(false);
  const [isUnHoldDisabled, setIsUnHoldDisabled] = useState(false);
  const [isUnMuteDisabled, setIsUnMuteDisabled] = useState(false);
  const [phoneCallEnabled, setPhoneCallEnabled] = useState("");
  const [userDataId, setUserDataId] = useState("");
  const [formData, setFormData] = useState({ adminId: "" });
  const [isCallMergedWithCn, setIsCallMergedWithCn] = useState(false);
  const [callNotesList, setCallNotesList] = useState([]);
  const [callCloseWarningPopup, setCallCloseWarningPopup] = useState(false);

  const [showMockDispositionModal, setShowMockDispositionModal] = useState(false);

  const [
    alternateMobileNoSubmissionMessage,
    setAlternateMobileNoSubmissionmessage,
  ] = useState({
    status: false,
    message: "",
  });
  const [familyCallReportActionCompleted, setFamilyCallReportActionCompleted] =
    useState({});
  const [familyCallReportAction, setFamilyCallReportAction] = useState({});

  const [memberInfoMode, setMemberInfoMode] = useState({
    mobileNo: "display",
    mainMembershipNo: "display",
  });

  const [callTransferForm, setCallTransferForm] = useState({
    careNavigatorId: "",
  });
  const [callTransferFormErrors, setCallTransferFormErrors] = useState({
    careNavigatorId: "",
  });

  const [agentData, setAgentData] = useState([]);
  

  const [memberInfoChangeErrors, setMemberInfoChangeErrors] = useState({});
  const [isMobilNoChangedByUser, setIsMobilNoChangedByUser] = useState({});
  const [isAltMobileNoChangedByUser, setIsAltMobilNoChangedByUser] = useState(
    {}
  );

  const [alternateMobileNo, setAlternateMobileNo] = useState("");
  const [alternateMobileNoError, setAlternateMobileNoError] = useState("");

  const [memberCallList, setMemberCall] = useState([]);
  const [memberCallHistory, setmemberCallHistory] = useState([]);
  const [showcallHistoryDetailsModal, setShowcallHistoryDetailsModal] = useState(false);
  const [showTmHistoryModal, setShowTmHistoryModal] = useState(false);
  const [tmHistory, setTmHistory] = useState(false);
  const [callScripts, setCallScripts] = useState({
    english: "",
    arabic: "",
  });

  const [callNotes, setCallNotes] = useState("");
  const [callNotesErrors, setCallNotesErrors] = useState("");
  const [callNotesSubmissionMessage, setCallNotesSubmissionMessage] =
    useState("");

  const [dispositionForm, setDispositionForm] = useState({
    dataRating: "",
    remarks: "",
    reason: "",
  });
  const [timer, setTimer] = useState({
    seconds: "00",
    minutes: "00",
    hours: "00",
    isTimerAlreadyStarted: false,
    intervalId: null,
  });
  const [callReportActionType, setCallReportActionType] = useState("");
  const [requestStatus, setRequestStatus] = useState({
    isAlternateMobileNoLoading: false,
    isRefreshPoolLoading: false,
    isCallbackLoading: false,
    isRejectEnrollmentLoading: false,
    isTransferCallLoading: false,
    isSearchLoading: false,
    isBookingLoading: false,
    isDispositionSaveLoading: false,
    isEnrolling: false,
    isEnrollLaterLoading: false,
    isIncorrectDataLoading: false,
    isCallDisconnectLoading: false,
    isCallMergeLoading: false,
    isCallPlacingRequestLoading: false,
    isRefreshLoading: false
  });

  const [showUploadTelehealthModal, setShowUploadTeleHealthModal] = useState(false);
  const [files, setFiles] = useState([]);
  const [fileUrlId, setFileUrlId] = useState("");
  const [allChecked, setAllChecked] = useState(false);
  const [checked, setChecked] = useState([]);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [showCancellationConfirmationModal, setShowCancellationConfirmationModal] = useState(false);
  const [isTeleHealthAllowedTobook, setIsTeleHealthAllowedToBook] = useState(false);
  const [doctorName, setDoctorName] = useState("");
  const [showButton, setShowButton] = useState(false);
  const [mockDispositionForm, setMockDispositionForm] = useState({ 
    callStatus: ""
  }); 

  const [remoteAppointmentData, setRemoteAppointmentData] = useState({
    bookingId: "", 
    appointmentId: ""
  });


  const uploadColumns = [
    {
      name: (
        <div>
          <input
            className="form-check-input"
            type="checkbox"
            id="flexCheckAll"
            onChange={handleAllChange}
            checked={allChecked}
          />
        </div>
      ),
      cell: row => (
        <input
          className="form-check-input"
          type="checkbox"
          id={`flexCheck${row.fileId}`}
          onChange={() => handleCheckboxChange(row.fileId)}
          checked={row.disable ? true : checked.includes(row.fileId)}
          disabled={row.disable}
        />
      ),
      sortable: false,
    },
    {
      name: 'Date',
      selector: row => row.date,
      sortable: true,
    },
    {
      name: 'Report Type',
      selector: row => row.reportType,
      sortable: true,
    },
    {
      name: 'Description',
      selector: row => row.description,
      sortable: true,
    },
    {
      name: 'Uploaded By',
      selector: row => row.uploadedBy,
      sortable: true,
    },
    {
      name: 'View',
      cell: row => (
        <div>
          <button onClick={() => {
            setFileUrlId(row.fileId);
            getHealthlockerFileUrl(row.fileId);
          }} className={`btn btn-success health-locker-btn me-2 ${fileUrlId == row.fileId ? "hide" : ""}`}><i className="bi bi-eye"></i>
          </button>
          {
            fileUrlId === row.fileId &&
            <span><img src="https://storage.googleapis.com/ksabupatest/2024/09/24/3lsab/y968s2s843.gif" style={{ height: "35px" }} alt="" /></span>
          }
        </div>

      ),
      sortable: false,
    },
  ];


  // timer on call start 
  useEffect(() => {
    // Start timer only once when callStatus becomes "in-progress"
    if (!timer.isTimerAlreadyStarted && callStatus === 'in-progress') {
      toggleTimer();
      setTimer(prevTimer => ({
        ...prevTimer,
        isTimerAlreadyStarted: true,
      }));
    } else if ((callStatus === 'completed' || callStatus === 'idle') && timer.isTimerAlreadyStarted) {
      // Stop timer when callStatus becomes "completed" or "idle"
      toggleTimer(true);
      setTimer({
        ...timer,
        isTimerAlreadyStarted: false,
        intervalId: null,
      });
    }
  }, [callStatus, toggleTimer, timer.isTimerAlreadyStarted]);

  useEffect(() => {
    ringtoneAudio.current = new Audio("/caller-tune.mp3");
    transferAudio.current = new Audio("/caller-tune.mp3")
  }, []);


  useEffect(() => {
    if (singleUserDataList?.length > 0 && Object.keys(singleUserData).length === 0) {
      setSingleUserData(singleUserDataList[0]);
    }
  }, [singleUserDataList]);

  useEffect(() => {
    let data = document.getElementById("agentCallModal3");
    const modal = new window.bootstrap.Modal(data);
    setCallModal(modal);

    return () => {
      modal.dispose();
    }
  }, []);

  useImperativeHandle(ref, () => {
    return {
      show: () => {
        callModal.show();
        setRescheduleAppointment(""); 

        setRemoteAppointmentData({ 
          bookingId: "",
          appointmentId: "", 
        }); 
  
        setRescheduleAppointment(""); 
        setDoctorName(""); 


        // reset timer 
        setTimer({
          seconds: "00",
          minutes: "00",
          hours: "00",
          isTimerAlreadyStarted: false,
          intervalId: null,
        });
      },

      hide: () => {
        callModal.hide();
        setReflect(!reflect); 
        setSingleUserData({}); 
      }, 

      handleRemoteReschedule: (doctorId, doctorName, bookingId, appointmentId) => { 
        setRescheduleAppointment(doctorId);
        setDoctorName(doctorName);
        setShowTeleHealthModal(true);
        setReflect(!reflect);
       
        setRemoteAppointmentData({ 
          bookingId: bookingId || "", 
          appointmentId: appointmentId || ""
        });         
      }, 


      handleRemoteFileUpload: (userId, callId) => { 
        handleUploadFilesModal(null, userId, callId); 
      }
      
    }
  });


  function handlePostTeleHealthBooking(data = null) {
    if (!data) return;

    setShowTeleHealthModal(false);

    setSingleUserDataList((prev) => {
      return prev.map((user) => {
        if (user.memberId === singleUserData['memberId']) {
          return { ...user, actionData: { ...user.actionData, ...data } }
        } else {
          return user;
        }
      })
    });


    setSingleUserData((prev) => {
      return {
        ...prev,
        actionData: {
          ...prev.actionData, ...data
        }
      }
    })
  }


  const handleCheckboxChange = (fileId) => {
    setChecked(prevChecked =>
      prevChecked.includes(fileId) ? prevChecked.filter(item => item !== fileId) : [...prevChecked, fileId]
    );
  };

  function handleAllChange(e) {
    const { checked } = e.target;
    if (checked) {
      setChecked(files.filter(item => !item.disable).map(item => item.fileId));
    } else {
      setChecked([]);
    }
  };



  const customStyles = {
    headCells: {
      style: {
        backgroundColor: '#e9e9e9',
        fontWeight: '700',
        fontSize: '16px',
        whiteSpace: 'normal',
      },
    },
    rows: {
      style: {
        backgroundColor: '#d1f2ee',
        '&:nth-of-type(odd)': {
          backgroundColor: '#dcfffb',
        },
        whiteSpace: 'normal',
        fontSize: '16px',
      },
    },
    cells: {
      style: {
        whiteSpace: 'normal',
      },
    },
  };

  const handleMockedCall = async () => {
    if (webexCall) dispatch(setWebexCall(null));
    if (
      callingAgent === "webex" &&
      (await checkMicrophonesAndPermission()) === false
    ) {
      setShowCheckMicrophonePermissionModal(true);
      return;
    }
    // checking if call is already in progress
    if (callStatus !== "idle") return;

    setIsCallPlaced(true);
    // resetting call states
    setCallStatus("initiating");
    setDisplayCallStatus("Calling");
    setCallInfoMessaage("");
    setIsCallConnected(false);

    setCallReportAction("");
    setTimer({
      seconds: "00",
      minutes: "00",
      hours: "00",
      isTimerAlreadyStarted: false,
      intervalId: null,
    });
    setCallReportActionType("");

    setRequestStatus((status) => ({
      ...status,
      isCallPlacingRequestLoading: true,
    }));
    try {
      let response = await tm_fetch_soft_number({
        userId: singleUserData.encUserId,
        universalConfNumber,
        poolId: singleUserData.poolId,
        callFrom: callingAgent,
      });
      if (response.data.code === 200) {
        let confNumber = response.data.data.confNumber;
        setConferenceNumber(confNumber);
        setUniversalConfNumber(response.data.data.universalConfNumber);

        // creating call based on the calling agent
        if (callingAgent === "webex") {
          //  Get media devices
          let localAudioStream = await window.Calling?.createMicrophoneStream({
            audio: true,
          });
          setWebexLocalMediaStream(localAudioStream);
          const destination = response.data.data.extensionNo;

          // Create the call
          let call = webexLine.makeCall({
            type: "uri",
            address: `${destination}`,
          });

          dispatch(setWebexCall(call));
          call.dial(localAudioStream);
          playRingtone();

          call.on("remote_media", (track) => {
            stopRingtone();
            callAudio.current.srcObject = new MediaStream([track]);
          });

          call.on("connect", (correlationId) => {
            stopRingtone();
            setIsMuteDisabled(true);
            setIsHoldDisabled(true);
            setDisplayCallStatus("Connected");
            setIsCallConnected(true);
            setIsTeleHealthAllowedToBook(true);
          });

          call.on("established", (correlationId) => {
            stopRingtone();
            setCallStatus("in-progress");
          });

          call.on("call_error", async (e) => {
            stopRingtone();
            call.end();
            const errorInfo = {
              code: e.code || null,
              message: e.message || "No message available",
              stack: e.stack || null,
              details: e.details || null,
              name: e.name || "Unknown Error",
            };

            await soft_number_logger({
              errorAt: "Error with call",
              ...errorInfo,
            });
          });

          call.on("disconnect", () => {
            setCallStatus("idle");
            setIsMuteDisabled(false);
            setIsHoldDisabled(false);
            stopRingtone();
          });

          call.on("held", () => {
            setIsHoldDisabled(false);
          });
        } else {
          setCallStatus("in-progress");
          setIsTeleHealthAllowedToBook(true);
        }
      }
    } catch (e) {
      const errorInfo = {
        code: e.code || null,
        message: e.message || "No message available",
        stack: e.stack || null,
        details: e.details || null,
        name: e.name || "Unknown Error",
      };
      await soft_number_logger({ errorAt: "Error with call", ...errorInfo });
      setCallStatus("idle");
      stopRingtone();
    } finally {
      setRequestStatus((status) => ({
        ...status,
        isCallPlacingRequestLoading: false,
      }));
    }
  };

  async function checkMicrophonesAndPermission() {
    try {
      // Check if the browser supports getUserMedia
      if (!navigator.mediaDevices || !navigator.mediaDevices.getUserMedia) {
        return false;
      }

      // Request microphone permissions
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });

      // Get list of audio input devices
      const devices = await navigator.mediaDevices.enumerateDevices();
      const microphones = devices.filter(
        (device) => device.kind === "audioinput"
      );

      if (microphones.length === 0) {
        // alert('No microphones found');
        return false;
      }

      // Clean up the stream
      stream.getTracks().forEach((track) => track.stop());

      return true;
    } catch (error) {
      // alert('Please Check Microphone Permissions');
      return false;
    }
  }

  function playRingtone(transfer = false) {
    if (transfer) {
      transferAudio.current.currentTime = 0;
      transferAudio.current.play();
    } else {
      ringtoneAudio.current.currentTime = 0;
      ringtoneAudio.current.play();
    }
  }

  function stopRingtone(transfer = false) {
    if (transfer) {
      transferAudio.current.pause();
      transferAudio.current.currentTime = 0;
    } else {
      ringtoneAudio.current.pause();
      ringtoneAudio.current.currentTime = 0;
    }
  }

  async function popupMockDisconnectCall(disconnectedBy = "") {


    if (callingAgent === "webex") {
      stopRingtone();
      webexCall?.end();
      callAudio.current?.srcObject
        ?.getTracks()
        .forEach((track) => track.stop());
    }

    if (disconnectedBy === "agent") {
      setCallStatus("disconnecting");
      setDisplayCallStatus("Disconnecting");
    }

    if (disconnectedBy === "agent" && !showDispositionModal) {
    }

    try {
      await tm_direct_agent_call_disconnect({
        confNumber: conferenceNumber
      });

      setShowMockDispositionModal(true);
    } catch (e) {
    } finally {
      setRequestStatus((status) => ({
        ...status,
        isCallDisconnectLoading: false,
      }));
    }

    setDispositionForm({
      dataRating: "",
      remarks: "",
    });

    setCallStatus("idle");
    setIsCallDisabled(true);
    setIsHoldDisabled(false);
    setIsMuteDisabled(false);
    setIsDisconnectDisabled(false);
    setIsUnHoldDisabled(false);
    setIsUnMuteDisabled(false);
    dispatch(setWebexCall(null));
  }

  const popupInitiateCall = async () => {
    try {
      //setInvitedDataId(inviteId);
      let resp = await agent_call_initiate(userDataId);

      setIsCallDisabled(false);
      setIsHoldDisabled(true);
      setIsMuteDisabled(true);
      setIsDisconnectDisabled(true);
    } catch (error) {
      console.error(error);
    }
    //console.log("phonenumber",phonenumber);
  };

  const popupHoldCall = async (unholdTo) => {
    webexCall.doHoldResume();

    setIsHoldDisabled(!isHoldDisabled);
  };

  // muting call
  const popupMuteCall = async (mute) => {
    webexCall.mute(webexLocalMediaStream);
    setIsMuteDisabled(!isMuteDisabled);
    setIsUnMuteDisabled(!isUnMuteDisabled);

    return;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    //console.log('isExist',isTransfer);
    let resp = await agent_transfer_call(userDataId, formData);

    console.log("Form submitted successfully");
    // Perform any further actions like sending data to a server
  };

  const handleChange = (e) => {
    const { value } = e.target;
    setCallTransferForm({
      careNavigatorId: value,
    });

    setCallTransferFormErrors({
      ...callTransferFormErrors,
      [e.target.name]: "",
    });
  };

  async function popupDisconnectCall(disconnectedBy = "") {
    if (disconnectedBy === "agent") {
      setCallStatus("disconnecting");
      setDisplayCallStatus("Disconnecting");
    }

    if (disconnectedBy === "agent" && !showDispositionModal) {

    }

    setDispositionForm({
      dataRating: "",
      remarks: "",
    });

    setRequestStatus((status) => ({
      ...status,
      isCallDisconnectLoading: true,
    }));

    try {
      let resp = await agent_call_disconnect(conferenceNumber);
    } catch (error) {
      console.error(error);
    } finally {
      setRequestStatus((status) => ({
        ...status,
        isCallDisconnectLoading: false,
      }));
    }

    setIsCallDisabled(true);
    setIsHoldDisabled(false);
    setIsMuteDisabled(false);
    setIsDisconnectDisabled(false);
    setIsUnHoldDisabled(false);
    setIsUnMuteDisabled(false);
    setDisplayCallStatus("Calling");
  }


  function onMemberInfoChange(e) {
    let isValid = true;
    let errors = {};
    if (e.target.name === "mobile" || e.target.name === "altMobile") {
      if (!/^\s*[+-]?(?:\d+|\d*\.\d+)?\s*$/.test(e.target.value)) {
        isValid = false;
        let fieldName =
          e.target.name === "mobile" ? "Mobile Number" : "Alternate Mobile No";
      }
    }

    if (e.target.name === "mainMembershipNo") {
      if (e.target.value.trim() === 0) {
        isValid = false;
      }

      if (!/^[0-9]*$/.test(e.target.value.trim())) {
        isValid = false;
      }
    }

    if (!isValid) {
      setMemberInfoChangeErrors({
        ...memberInfoChangeErrors,
        [singleUserData.memberId]: errors,
      });
      return;
    } else {
      setMemberInfoChangeErrors({
        ...memberInfoChangeErrors,
        [singleUserData.memberId]: {
          mainMembershipNo: "",
          altMobile: "",
          mobile: "",
        },
      });
    }

    setSingleUserDataList((previous) => {
      return previous.map((item) => {
        if (item.memberId === singleUserData.memberId) {
          return { ...item, [e.target.name]: e.target.value };
        }
        return item;
      });
    });

    setSingleUserData({
      ...singleUserData,
      [e.target.name]: e.target.value,
    });

    // if(e.target.name === "mobile"){
    setIsMobilNoChangedByUser({
      ...isMobilNoChangedByUser,
      [singleUserData.memberId]: true,
    });
    // }

    if (e.target.name === "altMobile") {
      setIsAltMobilNoChangedByUser({
        ...isAltMobileNoChangedByUser,
        [singleUserData.memberId]: true,
      });
    }
  }

  async function saveMainMembershipNo(e) {
    let mainMembershipNo = singleUserData.mainMembershipNo.trim();
    if (!mainMembershipNo) {
      setMemberInfoChangeErrors({
        ...memberInfoChangeErrors,
        [singleUserData.memberId]: {
          ...memberInfoChangeErrors[singleUserData.memberId],
          mainMembershipNo: "Main Membership No is required",
        },
      });
      return;
    }

    try {
      let resp = await updateMainMembershipNo({
        userId: singleUserData.encUserId,
        mainMembershipNo: mainMembershipNo,
      });
      if (resp.data.code === 200) {
        setReflect(!reflect);
        chagneMemberInfoFieldMode(e, "mainMembershipNo", "display");

        // update the main membership no in copy state as well here
        setSingleUserDataList((previous) => {
          return previous.map((item) => {
            item["copymainMembershipNo"] = mainMembershipNo;
            return item;
          });
        });

        setSingleUserData({
          ...singleUserData,
          copymainMembershipNo: mainMembershipNo,
        });
      }
    } catch (e) {
      console.log(e);
    }
  }

  function chagneMemberInfoFieldMode(e, field, mode) {
    if (mode === "edit") {
      let copyFieldName = field === "mobileNo" ? `copymobile` : `copy${field}`;

      // copying the original mobile no to revert it back in case of any error in updating new mobile No
      setSingleUserDataList((previous) => {
        return previous.map((item) => {
          item[copyFieldName] =
            field === "mobileNo" ? item["mobile"] : item[field];
          return item;
        });
      });

      setSingleUserData({
        ...singleUserData,
        [copyFieldName]:
          field === "mobileNo"
            ? singleUserData["mobile"]
            : singleUserData[field],
      });
    }

    setMemberInfoMode({
      ...memberInfoMode,
      [field]: mode,
    });
  }

  function chagneMemberInfoFieldMode(e, field, mode) {
    if (mode === "edit") {
      let copyFieldName = field === "mobileNo" ? `copymobile` : `copy${field}`;

      // copying the original mobile no to revert it back in case of any error in updating new mobile No
      setSingleUserDataList((previous) => {
        return previous.map((item) => {
          item[copyFieldName] =
            field === "mobileNo" ? item["mobile"] : item[field];
          return item;
        });
      });

      setSingleUserData({
        ...singleUserData,
        [copyFieldName]:
          field === "mobileNo"
            ? singleUserData["mobile"]
            : singleUserData[field],
      });
    }

    setMemberInfoMode({
      ...memberInfoMode,
      [field]: mode,
    });
  }

  async function saveMobileNo(e, mobileNo, type) {
    let isValid = true;
    let errorMsg = "";

    if (!/^\d*$/.test(mobileNo)) {
      errorMsg = "Enter Valid Mobile No";
      isValid = false;
    }

    if (!mobileNo) {
      errorMsg = "Enter Valid Mobile No";
      isValid = false;
    }

    if (!isValid) {
      setMemberInfoChangeErrors({
        ...memberInfoChangeErrors,
        [singleUserData.memberId]: {
          ...memberInfoChangeErrors[singleUserData.memberId],
          mobileNo: errorMsg,
        },
      });
      return;
    }

    try {
      let resp = await updateMobileNo(singleUserData.encUserId, mobileNo, type);
      if (resp.data?.code === 200) {
        chagneMemberInfoFieldMode(e, "mobileNo", "display");

        // update the main membership no in copy state as well here
        setSingleUserDataList((previous) => {
          return previous.map((item) => {
            if (item.memberId === singleUserData.memberId) {
              item["extensionNo"] = resp?.data?.data?.extensionNo;
            }
            item["copymobile"] = mobileNo;
            return item;
          });
        });

        setSingleUserData({
          ...singleUserData,
          extensionNo: resp?.data?.data?.extensionNo,
          copymobile: mobileNo,
        });
      } else {
        setMemberInfoChangeErrors({
          ...memberInfoChangeErrors,
          [singleUserData.memberId]: {
            ...memberInfoChangeErrors[singleUserData.memberId],
            mobileNo: resp?.data?.data?.msg || "Something went wrong",
          },
        });
      }
    } catch (e) {
      console.log(e);
    }
  }

  function onAlternateMobileNoChange(e) {
    setAlternateMobileNo(e.target.value);
    isAltNoChangedByUser.current = true;
  }

  const handleShowcallHistoryDetailsModalFamilyNotes = async (userId) => {
    let response = await fetch_members_notes({ userId });
    if (response.data?.code === 200) {
      setCallNotesList(response.data?.data || []);
    }
  };


  function handleUPloadFile() {
    let selectedData = [];

    for (let fileId of checked) {
      const row = files.find(item => item.fileId === fileId);

      selectedData.push({
        fileId
      });
    }


    upload_edge_file({ bookingId: singleUserData.actionData?.bookingId, callId: singleUserData.actionData?.appointmentId, membershipNo: singleUserData.memberId, userId: singleUserData.encUserId, selectedData: JSON.stringify(selectedData) }).then((response) => {
      if (response?.data?.code === 200) {
        setShowUploadTeleHealthModal(false);
      }
    }).catch(() => {

    }).finally(() => {

    })
  }


  const handleShowcallHistoryDetailsModalFamily = (userId, historyFor = "call") => {
    service_call_tm_history({userId}).then((response) => {
      setmemberCallHistory(response.data?.data?.callHistory);
      setTmHistory(response?.data?.data?.tmHistory);
      if (historyFor === "call") {
        setShowcallHistoryDetailsModal(true);
      } else {
        setShowTmHistoryModal(true);
      }
    }).catch((e) => {

    }).finally(() => { });

  };

  const changeCallNotes = (e) => {
    setCallNotes(e.target.value);
    setCallNotesErrors("");
  };

  async function saveCallNotes(e, userId) {
    if (!callNotes) {
      setCallNotesErrors("Please Add Call Notes");
      return;
    }

    let resp;

    try {
      resp = await save_members_notes({
        userId: userId,
        notes: callNotes,
        confNumber: conferenceNumber,
      });

      if (resp?.data.code === 200) {
        setCallNotes("");
        setCallNotes("");
        setCallNotesSubmissionMessage(resp?.data?.data?.msg);
        fetchCallNotesList(userId);
      } else {
        setCallNotesSubmissionMessage(resp?.data?.data?.msg);
      }
    } catch (e) {
      setCallNotesSubmissionMessage("Something went wrong");
    }

    setTimeout(() => {
      setCallNotesSubmissionMessage("");
    }, 2000);
  }

  async function fetchCallNotesList(userId) {
    let response;
    try {
      response = await fetch_members_notes({ userId });

      if (response.data?.code === 200) {
        setCallNotesList(response.data?.data || []);
      }
    } catch (error) { }
  }

  function mockCallPopupClose() {
    setReflect(!reflect);
    setSingleUserData({});
    setIsTeleHealthAllowedToBook(false);
  }

 function toggleTimer(stop = false) {
    let { seconds, minutes, hours, isTimerAlreadyStarted, intervalId } = timer;

    seconds = parseInt(seconds);
    minutes = parseInt(minutes);
    hours = parseInt(hours);

    if (!stop && !isTimerAlreadyStarted) {

      intervalId = setInterval(() => {
        seconds++;

        if (seconds === 60) {
          seconds = 0;
          minutes++;
        }

        if (minutes === 60) {
          minutes = 0;
          hours++;
        }

        const formattedSeconds = seconds < 10 ? "0" + seconds : seconds;
        const formattedMinutes = minutes < 10 ? "0" + minutes : minutes;
        const formattedHours = hours < 10 ? "0" + hours : hours;

        setTimer({
          seconds: formattedSeconds,
          minutes: formattedMinutes,
          hours: formattedHours,
          isTimerAlreadyStarted: true,
          intervalId: intervalId,
        });
      }, 1000);

    } else {
      clearInterval(intervalId);
      setTimer({
        ...timer,
        isTimerAlreadyStarted: false,
        intervalId: null,
      });
    }
  }

  function closeTelehealthModal() {
    setShowTeleHealthModal(false);
  }

  async function refrshMember() {
    let response;
    setRequestStatus((prev) => {
      return { ...prev, isRefreshLoading: true };
    })
    try {
      response = await service_refresh_member_data({ userId: singleUserData.encUserId });

      if (response.data?.code === 200) {
        setSingleUserDataList((prev) => (
          prev.map((item) => {
            if (item.memberId === response?.data?.data?.membershipNo) {
              return { ...item, ...response.data.data };
            } else {
              return item;
            }
          })
        ));


        setSingleUserData((prev) => {
          if (prev.memberId === response?.data?.data?.membershipNo) {
            return { ...prev, ...response.data.data };
          }

          return prev;
        });
      }
    } catch (error) { }
    finally {
      setRequestStatus((prev) => {
        return { ...prev, isRefreshLoading: false };
      })
    }
  }

  const spinStyle = {
    animation: "spin 1s linear infinite"
  };


  function handleTeleMedicineReschedule() {
    setRescheduleAppointment(singleUserData?.actionData?.doctorId);
    setDoctorName(singleUserData?.actionData?.doctorName);
    setShowTeleHealthModal(true);
  }


  async function handleCancelAppointment() {

    
    let bookingId = singleUserData?.actionData?.bookingId;
    let appointmentId = singleUserData?.actionData?.appointmentId;

    setIsLoading(true);
    try {
      let response = await cancel_or_reschedule_appointment("cancel", bookingId, appointmentId, null, null, null);

      if (response?.data?.code === 200) {
        setSingleUserDataList((prev) => {
          return prev.map((user) => {
            if (user.memberId === singleUserData.memberId) {
              return { ...user, actionData: { ...user.actionData, isBooked: false } }
            }

            return user;
          })
        });

        setSingleUserData((prev) => { 
          return { ...prev, actionData: { ...prev.actionData, isBooked: false } }
        });

        setShowCancellationConfirmationModal(false);
        setReflect(!reflect);
      }

    } catch (e) {

    } finally {
      setIsLoading(false);
    }
  }


  const getHealthlockerFileUrl = async (fileId) => {

    fetch_healthlocker_file_url({ fileId })
      .then((response) => {
        if (response.data.code === 200) {
          window.open(response.data.data.fileUrl, '_blank', 'noopener,noreferrer');
          setFileUrlId("");
        }
      }).catch(function (error) {
        console.log(error);
      });
  }

  const getHealthlockerFiles = (userId=null, callId=null) => {
    let booinguserId = userId ? userId : singleUserData.encUserId; 
    let bookingCallId = callId? callId:  singleUserData.actionData?.appointmentId; 

    setButtonLoading(true);
    fetch_healthlocker_files_v2({ userId: booinguserId, reportTypeId: 'all', callId: bookingCallId })
      .then((response) => {
        if (response.data.code === 200) {
          setFiles(response?.data?.data?.files);
          setShowButton(response?.data?.data?.showButton);

        }
      }).catch(function (error) {
        console.log(error);
      }).finally(() => {
        setButtonLoading(false);
      });
  }


  function handleUploadFilesModal(e, userId, callId) {
    console.log(userId, callId);
    getHealthlockerFiles(userId, callId);
    setShowUploadTeleHealthModal(true)
  }

  async function saveMockDisposition() {
    let response; 
    

    try {
          response = await service_direct_call_disposition({ userId: singleUserData['userId'], confNumber: conferenceNumber, callStatus: mockDispositionForm.callStatus, universalConfNumber: universalConfNumber });
          setCallReportActionType("");
        } catch (e) {}finally{ 
          setShowMockDispositionModal(false);
      }


      setMockDispositionForm({
        callStatus : ''
      })
  }

  

  const handleMockDispositionChange = (e) => {
    setMockDispositionForm({ 
      callStatus: e.target.value  
    }); 
  }

  const [loadingStates, setLoadingStates] = useState({});
  
  const refreshMember = async (memberId) => {
    setLoadingStates(prev => ({ ...prev, [memberId]: true }));
    
    try {
      await refrshMember(memberId);
    } finally {
      setLoadingStates(prev => ({ ...prev, [memberId]: false }));
    }
  };


  return (
    <div
      className="modal fade "
      id="agentCallModal3"
      tabIndex="1"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
      aria-labelledby="agentCallModalLabel"
      aria-hidden="true"
      ref={callModalRef}
    >
      <div className="modal-dialog modal-dialog-centered modal-xl tw-scale-95 tw-max-h-[90vh]">
        <div className="modal-content">
          <div className="agent-call-box">
            <div className="row align-items-center">
              <div className="col-4 d-flex align-items-center">
                <i className="">
                  <img
                    src="https://storage.googleapis.com/ksabupatest/2024/09/24/81n4z/zjbjfj88hl.png"
                    style={{ height: "30px", verticalAlign: "baseline" }}
                    alt=""
                  />
                </i>
                <h2 className="coach-card-title ms-2">
                  <br />
                </h2>
              </div>
              <div className="col">
                { 
                  singleUserData['isFamily'] && 
                  (  
                    <h3 className="coach-card-subtitle">
                      <i className="">
                        {" "}
                        <img
                          src="https://storage.googleapis.com/ksabupatest/2024/09/24/sl3hn/jh81c0h9x0.png"
                          style={{ height: "15px", verticalAlign: "baseline" }}
                          alt=""
                        />
                      </i>{" "}
                      Family:{" "}
                      <span style={{ color: "#32C100", fontWeight: "500" }}>
                        {singleUserDataList.length} Members
                      </span>
                    </h3>
                  )
                }
                {/* )} */}
              </div>
              <div className="col-auto">
                {softCallEnabled === "Y" ? (
                  <>
                    {callStatus === "idle" ? (
                      <button
                        onClick={() =>
                          handleMockedCall(singleUserData.encUserId)
                        }
                        disabled={callStatus === "connected"}
                        className="btn btn-dark coach-calling-btn mx-2"
                        alt="soft phone call"
                      >
                        <i className="bx bxs-phone"></i>
                      </button>
                    ) : callingAgent === "webex" ? (
                      <button
                        type="button"
                        className="text-white"
                        style={{
                          fontSize: "19px",
                          fontWeight: "400",
                          marginRight: "20px",
                          backgroundColor: "transparent",
                          border: "1px solid #eee",
                          borderRadius: "10px",
                        }}
                      >
                        {displayCallStatus}
                      </button>
                    ) : (
                      <div>
                        {requestStatus.isCallPlacingRequestLoading && (
                          <button
                            type="button"
                            className="btn btn-dark coach-call-close-btn"
                            onClick={() => popupMockDisconnectCall("agent")}
                            disabled={
                              requestStatus.isCallPlacingRequestLoading
                                ? "disabled"
                                : ""
                            }
                          >
                            <i className="bx bxs-phone"></i>
                          </button>
                        )}

                        {!requestStatus.isCallPlacingRequestLoading && (
                          <button
                            type="button"
                            className="btn btn-dark coach-call-close-btn"
                            onClick={() => popupMockDisconnectCall("agent")}
                          >
                            <i className="bx bxs-phone"></i>
                          </button>
                        )}
                      </div>
                    )}
                  </>
                ) : (
                  ""
                )}
                {phoneCallEnabled === "Y" ? (
                  <>
                    {isCallDisabled ? (
                      <button
                        type="button"
                        className="btn btn-dark coach-calling-btn mx-2"
                        onClick={() => popupInitiateCall()}
                      >
                        <i className="bx bxs-phone"></i>
                      </button>
                    ) : (
                      <button
                        type="button"
                        className="btn btn-dark coach-calling-btn mx-2"
                        onClick={() => popupInitiateCall()}
                        disabled
                      >
                        <i className="bx bxs-phone"></i>
                      </button>
                    )}
                  </>
                ) : (
                  ""
                )}
              </div>
              <div className="col-auto">
                <div className="coach-call-btn-box">
                  {isMuteDisabled ? (
                    <button
                      type="button"
                      className="btn btn-dark coach-call-btn mx-2"
                      onClick={() => popupMuteCall("Y")}
                    >
                      <i className="bi">
                        <img
                          src="https://storage.googleapis.com/ksabupatest/2024/09/24/jatay/rl88h4ko39.png"
                          style={{ height: "28px" }}
                          alt=""
                        />
                      </i>
                      Mute
                    </button>
                  ) : (
                    <>
                      {isUnMuteDisabled && callStatus === "in-progress" ? (
                        <button
                          type="button"
                          className="btn btn-dark coach-call-btn mx-2"
                          onClick={() => popupMuteCall("N")}
                        >
                          <i className="bi">
                            <img
                              src="https://storage.googleapis.com/ksabupatest/2024/09/24/jatay/rl88h4ko39.png"
                              style={{ height: "28px" }}
                              alt=""
                            />
                          </i>
                          UnMute
                        </button>
                      ) : (
                        <button
                          type="button"
                          className="btn btn-dark coach-call-btn mx-2"
                          onClick={() => popupMuteCall("Y")}
                          style={{ display: "none" }}
                          disabled
                        >
                          <i className="bi">
                            <img
                              src="https://storage.googleapis.com/ksabupatest/2024/09/24/jatay/rl88h4ko39.png"
                              style={{ height: "28px" }}
                              alt=""
                            />
                          </i>
                          Mute
                        </button>
                      )}
                    </>
                  )}

                  {callingAgent === "webex" &&
                    callStatus === "in-progress" &&
                    (isHoldDisabled ? (
                      <button
                        type="button"
                        className="btn btn-dark coach-call-btn mx-2"
                        onClick={() => popupHoldCall("user")}
                      >
                        <i className="bi">
                          <img
                            src="https://appcdn.goqii.com/storeimg/21247_1720158097.png"
                            style={{ height: "28px" }}
                            alt=""
                          />
                        </i>{" "}
                        Hold
                      </button>
                    ) : (
                      <>
                        <button
                          type="button"
                          className="btn btn-dark coach-call-btn mx-2"
                          onClick={() => popupHoldCall("user")}
                        >
                          <i className="bi">
                            <img
                              src="https://appcdn.goqii.com/storeimg/21247_1720158097.png"
                              style={{ height: "28px" }}
                              alt=""
                            />
                          </i>
                          UnHold
                        </button>
                      </>
                    ))}

                  {callingAgent === "webex" &&
                    callStatus !== "idle" &&
                    webexCall && (
                      <button
                        type="button"
                        className="btn btn-dark coach-call-close-btn"
                        onClick={() => popupMockDisconnectCall("agent")}
                      >
                        <i className="bx bxs-phone"></i>
                      </button>
                    )}
                </div>

                <h3 className="coach-card-subtitle text-center mt-1">
                  <i className="bx bx-time-five"></i> Call Duration{" "}
                  {timer.hours}:{timer.minutes}:{timer.seconds}
                </h3>
                <div className="call-status-message">
                  <p className="mb-0">{callInfoMessaage}</p>
                </div>

                {softCallEnabled === "N" || softCallEnabled === "N" ? (
                  <div className="coach-call-btn-box">
                    <form onSubmit={handleSubmit}>
                      <div className="row">
                        <div className="col">
                          <select
                            className="form-select input-field-ui"
                            id="agentId"
                            name="adminId"
                            value={formData["adminId"]}
                            onChange={handleChange}
                            aria-label="Default select example"
                          >
                            <option value="">Select Name</option>
                            {agentData.map((option) => (
                              <option key={option.id} value={option.id}>
                                {option.fullName}
                              </option>
                            ))}
                          </select>
                        </div>
                        <div className="col">
                          <button
                            type="submit"
                            className="btn btn-primary btn-sm call-transfer-btn"
                          >
                            Transfer Call
                          </button>
                          <button
                            type="submit"
                            className="btn btn-primary btn-sm call-transfer-exit-btn"
                          >
                            Transfer Call & Exit
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                ) : (
                  ""
                )}
              </div>
              {(softCallEnabled === "Y" || softCallEnabled === "Y") &&
                !isCallMergedWithCn ? (
                <div className="col-auto">
                  {isDiconnectDisabled && callStatus === "in-progress" ? (
                    <button
                      type="button"
                      className="btn btn-dark coach-call-close-btn"
                      onClick={() => popupDisconnectCall("agent")}
                    >
                      <i className="bx bxs-phone"></i>
                    </button>
                  ) : (
                    <button
                      type="button"
                      className="btn btn-dark coach-call-close-btn"
                      onClick={() => popupDisconnectCall("agent")}
                      style={{ display: "none" }}
                      disabled
                    >
                      <i className="bx bxs-phone"></i>
                    </button>
                  )}
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
          <div className="modal-body agent-call-box-1">
            <div className="row">
              <div className="col-md-5">
                <div className="agent-bg-box-grey">
                  <div className="row align-items-center">
                    <div className="col-12 d-flex tw-justify-between mb-2">
                      <h5 className="user-card-title mb-2">
                        Member Information
                      </h5>


                    </div>
                    <div
                      className="col-12"
                      style={{ overflowY: "scroll", maxHeight: "420px" }}
                    >
                      <div
                        className="accordion accordion-flush h-100"
                        id="FamilyMemberTab"
                      >
                        {singleUserDataList.map((data, ind) => (
                          <div
                            className="accordion-item h-100"
                            key={ind}
                            area-expanded="true"
                          >
                            <h2
                              className="accordion-header"
                              id="HOFFamilyMember1"
                            >
                              <button
                                className={`accordion-button border active 
                                      ${data.memberId === singleUserData.memberId ? "" : "collapsed"}                                
                                  tw-relative `} 
                              >
                                <div className="tw-cursor-pointer tw-text-nowrap tw-w-full"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target={`#collapse${ind + 1}`}
                                aria-controls={`collapse${ind + 1}`}
                                aria-expanded="true"
                                onClick={(e) => {
                                  setSingleUserData(data);
                                }}
                                disabled={
                                  familyCallReportActionCompleted[
                                    singleUserData.memberId
                                  ] &&
                                    singleUserData.enrollmentStatus ===
                                    "inactive" &&
                                    familyCallReportAction[
                                    singleUserData.memberId
                                    ] !== "call_back"
                                    ? "disabled"
                                    : ""
                                }
                                >
                                  {singleUserData.hof == "Y" && "HOF"}{" "}
                                  {data.memberName}
                                  <br />
                                  <span
                                    style={{
                                      fontSize: "14px",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {data.memberType}
                                  </span>
                                </div>
                                <button
                                  className={`tw-border-none tw-bg-transparent tw-mr-2 ${
                                    loadingStates[data.memberId] ? "tw-animate-spin" : ""
                                  }`}
                                  style={{ cursor: "pointer" }}
                                  onClick={(e) => {
                                    e.stopPropagation(); // Prevent accordion toggle
                                    refreshMember(data.memberId);
                                  }}
                                >
                                  <i 
                                    className="bi bi-arrow-clockwise tw-text-white tw-text-lg" 
                                    style={{ 
                                      fontSize: "25px",
                                      ...(loadingStates[data.memberId] && spinStyle)
                                    }}
                                  />
                                </button>
                                <div className="tw-absolute tw-right-0 tw-h-full tw-w-10 tw-z-[9999]"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target={`#collapse${ind + 1}`}
                                aria-controls={`collapse${ind + 1}`}
                                aria-expanded="true"
                                onClick={(e) => {
                                  setSingleUserData(data);
                                }}
                                disabled={
                                  familyCallReportActionCompleted[
                                    singleUserData.memberId
                                  ] &&
                                    singleUserData.enrollmentStatus ===
                                    "inactive" &&
                                    familyCallReportAction[
                                    singleUserData.memberId
                                    ] !== "call_back"
                                    ? "disabled"
                                    : ""
                                }
                                ></div>
                              </button>
                            </h2>
                            <div
                              id={`collapse${ind + 1}`}
                              className={`accordion-collapse collapse h-100 ${data.memberId === singleUserData.memberId &&
                                "show"
                                }`}
                              aria-labelledby="HOFFamilyMember1"
                              data-bs-parent="#FamilyMemberTab"
                            >
                              <div
                                className="accordion-body p-0 ps-2 h-100"
                                style={{ background: "#f5f5f5" }}
                              >
                                <div className="row h-100">
                                  <div className="col-12 h-100">
                                    <ul
                                      className={`list-group list-group-flush ${singleUserDataList.length > 1 ? 'tw-max-h-[250px] tw-overflow-y-auto' : 'tw-h-full'}`}
                                      // style={{
                                      //   overflowY: "auto",
                                      //   maxHeight: "270px",
                                      // }}
                                    >
                                      <li className="list-group-item bg-transparent ps-0 tw-border-t-0 tw-border-l-0 tw-border-r-0">
                                        <div className="row">
                                          <h3 className="user-card-subtitle col-5">
                                            Member Name{" "}
                                          </h3>
                                          <h3 className="user-card-subtitle col-7">
                                            : {data.memberName || "-"}
                                          </h3>
                                        </div>
                                      </li>
                                      <li className="list-group-item bg-transparent ps-0 tw-border-t-0 tw-border-l-0 tw-border-r-0">
                                        <div className="row">
                                          <h3 className="user-card-subtitle col-5">
                                            Member ID{" "}
                                          </h3>
                                          <h3 className="user-card-subtitle col-7">
                                            : {data.memberId || "-"}
                                          </h3>
                                        </div>
                                      </li>
                                      <li className="list-group-item bg-transparent ps-0 tw-border-t-0 tw-border-l-0 tw-border-r-0">
                                        <div className="row">
                                          <h3 className="user-card-subtitle col-5">
                                            Main Membership No{" "}
                                          </h3>
                                          {memberInfoMode.mainMembershipNo ===
                                            "edit" ? (
                                            <div className="w-50 d-flex align-items-starts">
                                              <div>
                                                <input
                                                  ref={mobileEditInput}
                                                  type="text"
                                                  placeholder="Main Membership No"
                                                  name="mainMembershipNo"
                                                  className="form-control service-action ms-1 editable-mobile-no"
                                                  value={data.mainMembershipNo}
                                                  onChange={(e) =>
                                                    onMemberInfoChange(e)
                                                  }
                                                />
                                                <p
                                                  style={{
                                                    fontSize: "10px",
                                                    color: "red",
                                                  }}
                                                >
                                                  {memberInfoChangeErrors[
                                                    singleUserData.memberId
                                                  ]?.mainMembershipNo?.length >
                                                    0
                                                    ? memberInfoChangeErrors[
                                                      singleUserData.memberId
                                                    ]?.mainMembershipNo
                                                    : " "}
                                                </p>
                                              </div>
                                              <span
                                                className="mt-1"
                                                onClick={(e) => {
                                                  saveMainMembershipNo();
                                                }}
                                                style={{ cursor: "pointer" }}
                                              >
                                                <i className="bi bi-check-circle-fill ms-2"></i>
                                              </span>

                                              <span
                                                className="mt-1"
                                                onClick={(e) => {
                                                  setMemberInfoChangeErrors({
                                                    ...memberInfoChangeErrors,
                                                    [singleUserData.memberId]: {
                                                      ...memberInfoChangeErrors[
                                                      singleUserData.memberId
                                                      ],
                                                      mainMembershipNo: "",
                                                    },
                                                  });

                                                  setSingleUserDataList(
                                                    (previous) => {
                                                      return previous.map(
                                                        (item) => {
                                                          if (
                                                            item.memberId ===
                                                            singleUserData.memberId
                                                          ) {
                                                            item[
                                                              "mainMembershipNo"
                                                            ] =
                                                              singleUserData[
                                                              "copymainMembershipNo"
                                                              ];
                                                            return item;
                                                          }

                                                          return item;
                                                        }
                                                      );
                                                    }
                                                  );

                                                  setSingleUserData({
                                                    ...singleUserData,
                                                    mainMembershipNo:
                                                      singleUserData.copymainMembershipNo,
                                                  });
                                                  chagneMemberInfoFieldMode(
                                                    e,
                                                    "mainMembershipNo",
                                                    "display"
                                                  );
                                                }}
                                                style={{ cursor: "pointer" }}
                                              >
                                                <i className="bi bi-x-circle-fill ms-2"></i>
                                              </span>
                                            </div>
                                          ) : (
                                            <>
                                              <h3 className="user-card-subtitle col-7">
                                                :{data.mainMembershipNo || "-"}{" "}
                                              </h3>
                                            </>
                                          )}
                                        </div>
                                      </li>
                                      <li className="list-group-item bg-transparent ps-0 tw-border-t-0 tw-border-l-0 tw-border-r-0">
                                        <div className="row">
                                          <h3 className="user-card-subtitle col-5">
                                            Policy Member Type{" "}
                                          </h3>
                                          <h3 className="user-card-subtitle col-7">
                                            : {data.memberType || "-"}
                                          </h3>
                                        </div>
                                      </li>
                                      <li className="list-group-item bg-transparent ps-0 tw-border-t-0 tw-border-l-0 tw-border-r-0">
                                        <div className="row">
                                          <h3 className="user-card-subtitle col-5">
                                            Mobile No{" "}
                                          </h3>

                                          {memberInfoMode.mobileNo ===
                                            "edit" ? (
                                            <div className="w-50 d-flex align-items-start">
                                              <div>
                                                <input
                                                  ref={mobileEditInput}
                                                  type="text"
                                                  placeholder="Mobile No"
                                                  name="mobile"
                                                  className="form-control service-action  ms-1 editable-mobile-no"
                                                  value={data.mobile}
                                                  onChange={(e) =>
                                                    onMemberInfoChange(e)
                                                  }
                                                />

                                                <p
                                                  style={{
                                                    fontSize: "10px",
                                                    color: "red",
                                                  }}
                                                >
                                                  {memberInfoChangeErrors[
                                                    singleUserData.memberId
                                                  ]?.mobileNo?.length > 0
                                                    ? memberInfoChangeErrors[
                                                      singleUserData.memberId
                                                    ]?.mobileNo
                                                    : " "}
                                                </p>
                                              </div>
                                              <span
                                                className="mt-1"
                                                onClick={(e) =>
                                                  saveMobileNo(
                                                    e,
                                                    singleUserData.mobile,
                                                    "main"
                                                  )
                                                }
                                                style={{ cursor: "pointer" }}
                                              >
                                                <i className="bi bi-check-circle-fill ms-2"></i>
                                              </span>

                                              <span
                                                className="mt-1"
                                                onClick={(e) => {
                                                  setMemberInfoChangeErrors({
                                                    ...memberInfoChangeErrors,
                                                    [singleUserData.memberId]: {
                                                      ...memberInfoChangeErrors[
                                                      singleUserData.memberId
                                                      ],
                                                      mobileNo: "",
                                                    },
                                                  });

                                                  setSingleUserDataList(
                                                    (previous) => {
                                                      return previous.map(
                                                        (item) => {
                                                          if (
                                                            item.memberId ===
                                                            singleUserData.memberId
                                                          ) {
                                                            item["mobile"] =
                                                              singleUserData[
                                                              "copymobile"
                                                              ];
                                                            return item;
                                                          }

                                                          return item;
                                                        }
                                                      );
                                                    }
                                                  );

                                                  setSingleUserData({
                                                    ...singleUserData,
                                                    mobile:
                                                      singleUserData.copymobile,
                                                  });
                                                  chagneMemberInfoFieldMode(
                                                    e,
                                                    "mobileNo",
                                                    "display"
                                                  );
                                                }}
                                                style={{ cursor: "pointer" }}
                                              >
                                                <i className="bi bi-x-circle-fill ms-2"></i>
                                              </span>
                                            </div>
                                          ) : (
                                            <>
                                              <h3 className="user-card-subtitle col-7">
                                                : {data.mobile || "-"} &nbsp;
                                                &nbsp;
                                                <span
                                                  onClick={(e) =>
                                                    chagneMemberInfoFieldMode(
                                                      e,
                                                      "mobileNo",
                                                      "edit"
                                                    )
                                                  }
                                                  style={{ cursor: "pointer" }}
                                                >
                                                  <i className="bi bi-pencil-square me-2"></i>
                                                </span>
                                              </h3>
                                            </>
                                          )}
                                        </div>
                                      </li>
                                      <li className="list-group-item bg-transparent ps-0 tw-border-t-0 tw-border-l-0 tw-border-r-0">
                                        <div
                                          className=""
                                          style={{ display: "flex" }}
                                        >
                                          <h3 className="user-card-subtitle col-5">
                                            Alternate Mobile No{" "}
                                          </h3>
                                          <div>
                                            <input
                                              placeholder="Alternate Mobile No"
                                              name="altMobile"
                                              className="form-control service-action  ms-1 editable-mobile-no"
                                            />
                                          </div>
                                        </div>
                                      </li>
                                      <li className="list-group-item tw-border-t-0 tw-border-l-0 tw-border-r-0ist-group-item bg-transparent ps-0">
                                        <div className="row">
                                          <h3 className="user-card-subtitle col-5">
                                            Contract No
                                          </h3>
                                          <h3 className="user-card-subtitle col-7">
                                            : {data.contractNo || "-"}
                                          </h3>
                                        </div>
                                      </li>
                                      <li className="list-group-item bg-transparent ps-0 tw-border-t-0 tw-border-l-0 tw-border-r-0">
                                        <div className="row">
                                          <h3 className="user-card-subtitle col-5">
                                            Contract Name
                                          </h3>
                                          <h3 className="user-card-subtitle col-7">
                                            : {data.contractName || "-"}
                                          </h3>
                                        </div>
                                      </li>
                                      <li className="list-group-item bg-transparent ps-0 tw-border-t-0 tw-border-l-0 tw-border-r-0">
                                        <div className="row">
                                          <h3 className="user-card-subtitle col-5">
                                            Gender
                                          </h3>
                                          <h3 className="user-card-subtitle col-7">
                                            : {data.gender || "-"}
                                          </h3>
                                        </div>
                                      </li>
                                      <li className="list-group-item bg-transparent ps-0 tw-border-t-0 tw-border-l-0 tw-border-r-0">
                                        <div className="row">
                                          <h3 className="user-card-subtitle col-5">
                                            City
                                          </h3>
                                          <h3 className="user-card-subtitle col-7">
                                            : {data.city}
                                          </h3>
                                        </div>
                                      </li>
                                      <li className="list-group-item bg-transparent ps-0 tw-border-t-0 tw-border-l-0 tw-border-r-0">
                                        <div className="row">
                                          <h3 className="user-card-subtitle col-5">
                                            Policy Start Date
                                          </h3>
                                          <h3 className="user-card-subtitle col-7">
                                            : {data.policyStartDate || "-"}
                                          </h3>
                                        </div>
                                      </li>
                                      <li className="list-group-item bg-transparent ps-0 tw-border-t-0 tw-border-l-0 tw-border-r-0">
                                        <div className="row">
                                          <h3 className="user-card-subtitle col-5">
                                            Policy End Date
                                          </h3>
                                          <h3 className="user-card-subtitle col-7">
                                            : {data.policyEndDate || "-"}
                                          </h3>
                                        </div>
                                      </li>
                                      <li className="list-group-item bg-transparent ps-0 tw-border-t-0 tw-border-l-0 tw-border-r-0">
                                        <div className="row">
                                          <h3 className="user-card-subtitle col-5">
                                            Date of Birth{" "}
                                          </h3>
                                          <h3 className="user-card-subtitle col-7">
                                            : {data.dob ? data.dob + ' (' + calculateAge(data.dob) + ' years) ' : "-"}
                                          </h3>
                                        </div>
                                      </li>
                                      <li className="list-group-item bg-transparent ps-0 tw-border-t-0 tw-border-l-0 tw-border-r-0">
                                        <div className="row">
                                          <h3 className="user-card-subtitle col-5">
                                            Chronic Conditions
                                          </h3>
                                          <h3 className="user-card-subtitle col-7">
                                            : {data.chronicConditions || "-"}
                                          </h3>
                                        </div>
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  className="agent-bg-box-grey mt-3"
                  style={{ display: "none" }}
                >
                  <div className="row align-items-center">
                    <div className="col-auto">
                      <h5 className="col-form-label">Alternate Mobile No</h5>
                    </div>
                    <div className="col">
                      <input
                        placeholder="Alternate Mobile No"
                        className="form-control input-field-ui"
                        name="reason"
                        value={alternateMobileNo}
                        onChange={(e) => onAlternateMobileNoChange(e)}
                      />
                    </div>
                    <div className="col-12">
                      <div className="error message text-danger">
                        {alternateMobileNoError?.length > 0 && (
                          <p className="mb-0 mt-1"> {alternateMobileNoError}</p>
                        )}
                      </div>

                      {alternateMobileNoSubmissionMessage?.message?.length >
                        0 ? (
                        <div
                          className={
                            alternateMobileNoSubmissionMessage?.status
                              ? "text-success"
                              : "text-danger"
                          }
                        >
                          {alternateMobileNoSubmissionMessage?.message}
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-md px-0">
                {singleUserDataList.length !== 0 && (
                  <div
                    className="agent-bg-box-grey agent-bg-box-corner p-0"
                    style={{
                      border: "2px solid #003E6B",
                      background: "#fff",
                      position: "relative",
                      width: "100%",
                    }}
                  >
                    <div
                      style={{
                        height: "485px",
                        padding: "14px",
                        width: "100%",
                        justifyContent: "center",
                      }}
                      className=" d-flex "
                    >
                      <div className="row align-items-center w-100">

                        <div className="col-12 h-100 tw-p-0">
                          {
                            singleUserData?.actionTaken ? (
                              <ul className="list-group list-group-flush h-100 tw-overflow-y-auto tw-relative tw-justify-center">
                                {singleUserData.enrollmentStatus !== "inactive" ? (
                                    <div className="tw-absolute tw-z-[999] tw-w-full tw-top-0 tw-pt-10 tw-bg-white">
                                      <h5 className="user-card-title text-center">
                                        <img
                                          src={`${(singleUserData?.actionData?.message === "Not Interested" || singleUserData?.actionData?.message === "Incorrect") ? "https://storage.googleapis.com/ksabupatest/2025/01/28/bhgjv/s4ku84msns.png" : "https://storage.googleapis.com/ksabupatest/2024/09/24/dsqjh/kjnamjdjvj.png" }`}
                                          style={{ height: "20px" }}
                                        />{" "}
                                        {singleUserData?.actionData?.message}
                                      </h5>
                                    </div>
                                ) : (
                                  ""
                                )}
                                <div className="">
                               {singleUserData.actionData?.fullName && ( 
                                <li className="list-group-item tw-border-t-0 tw-border-l-0 tw-border-r-0">
                                  <div className="row">
                                    <h3 className="user-card-subtitle col-4">
                                      Engaged By 
                                    </h3>
                                    <h3 className="user-card-subtitle col-8">
                                      : {singleUserData.actionData?.fullName} {`( ${singleUserData.actionData?.actionBy} )`}
                                    </h3>
                                  </div>
                                </li>
                              )}
                                {singleUserData?.carenavigatorName && (
                                  <li className="list-group-item tw-border-t-0 tw-border-l-0 tw-border-r-0">
                                  <div className="row">
                                    <h3 className="user-card-subtitle col-4">
                                      CareNavigator
                                    </h3>
                                    <h3 className="user-card-subtitle col-8">
                                    : {singleUserData?.carenavigatorName || ""}
                                    </h3>
                                    </div>
                                    </li>
                                  )}

                                {
                                  (singleUserData?.enrollmentStatus === "active" && singleUserData?.actionData?.isBooked) && (
                                        <>

                                          {singleUserData?.actionData?.doctorName && (
                                            <li className="list-group-item tw-border-t-0 tw-border-l-0 tw-border-r-0">
                                            <div className="row">
                                              <h3 className="user-card-subtitle col-4">

                                                Doctor Name
                                              </h3>
                                              <h3 className="user-card-subtitle col-8 tw-capitalize">
                                                <span className="tw-mr-0.5">:</span>
                                                {singleUserData?.actionData?.doctorName}
                                              </h3>
                                            </div>
                                          </li>
                                        )}

                                          {singleUserData?.actionData?.appointmentDateTime && (
                                             <li className="list-group-item tw-border-t-0 tw-border-l-0 tw-border-r-0">
                                            <div className="row">
                                              <h3 className="user-card-subtitle col-4">

                                                Appointment Date
                                              </h3>
                                              <h3 className="user-card-subtitle col-8">
                                                <span className="tw-mr-0.5">: </span>
                                                {singleUserData?.actionData?.appointmentDateTime}
                                              </h3>
                                            </div>
                                          </li>
                                        )}
                                          {singleUserData?.actionData?.thStatus && (
                                            <li className="list-group-item tw-border-t-0 tw-border-l-0 tw-border-r-0">
                                            <div className="row">
                                              <h3 className="user-card-subtitle col-4">

                                                TeleHealth Status
                                              </h3>
                                              <h3 className="user-card-subtitle col-8">
                                                <span className="tw-mr-0.5">:</span>
                                                {singleUserData?.actionData?.thStatus}
                                              </h3>
                                            </div>
                                          </li>
                                        )}
                                          {singleUserData?.actionData?.bookingTime && (
                                            <li className="list-group-item tw-border-t-0 tw-border-l-0 tw-border-r-0">
                                            <div className="row">
                                              <h3 className="user-card-subtitle col-4">

                                                Booked On
                                              </h3>
                                              <h3 className="user-card-subtitle col-8">
                                                <span className="tw-mr-0.5">:</span>
                                                {singleUserData?.actionData?.bookingTime}
                                              </h3>
                                            </div>
                                          </li>
                                        )}
                                          {singleUserData?.actionData?.bookByName && (
                                            <li className="list-group-item tw-border-t-0 tw-border-l-0 tw-border-r-0">
                                            <div className="row">
                                              <h3 className="user-card-subtitle col-4">

                                                Booked By
                                              </h3>
                                              <h3 className="user-card-subtitle col-8">
                                                <span className="tw-mr-0.5">:</span>
                                                {` ${singleUserData?.actionData?.bookByName} (${singleUserData?.actionData?.bookBy})` }

                                              </h3>
                                            </div>
                                          </li>
                                        )}
                                          



                                          



                                          {/* {
                                            isTeleHealthAllowedTobook &&
                                            <li className="list-group-item">
                                              <div className="row">
                                                <h3 className="user-card-subtitle col-4">

                                                  Action :
                                                </h3>

                                                <h3 className="col-8">
                                                  <button onClick={e => handleUploadFilesModal(e)} className="btn health-locker-btn me-2"><i className="bi bi-upload"></i></button>
                                                  <button className="btn health-locker-btn me-2"
                                                    onClick={e => handleTeleMedicineReschedule()}
                                                  ><i className="bi bi-calendar4-week"></i></button>

                                                  <button onClick={e => { setShowCancellationConfirmationModal(true) }} className={`btn health-locker-btn me-2 `}><i className="bi bi-x-lg"></i></ button>
                                                </h3>
                                              </div>
                                            </li>
                                          } */}
                                        </>
                                  )
                                        
                                  
                                }
                                </div>

                                <div className="tw-absolute tw-bottom-0 tw-p-6 tw-flex tw-justify-center tw-w-full">
                                {/* singleUserData.enrollmentStatus === "active" && */}
                                { ( !singleUserData.actionData?.isBooked ) && (
                                <button className="btn btn-dark coach-call-enroll-btn text-center" onClick={e => setShowTeleHealthModal(true)}
                                  disabled={!isTeleHealthAllowedTobook ? "disabled": ''}  
                                >Telehealth Consultation
                                </button>
                                )}
                                {( singleUserData.actionData?.isBooked) && (
                                  <div className={`tw-flex tw-flex-row ${!singleUserData.actionData?.isBooked ? "tw-w-1/2" : "tw-w-full"} tw-justify-center`}>
                                    <OverlayTrigger placement="top" overlay={<Tooltip>Upload Files</Tooltip>}>
                                      <button
                                      disabled={!isTeleHealthAllowedTobook} 
                                      onClick={e => handleUploadFilesModal(e)} className="btn tw-text-white tw-bg-[#03335b] hover:tw-bg-[#1279BE] tw-rounded-xl me-2"><i className="bi bi-upload tw-text-lg"></i></button>
                                      </OverlayTrigger>
                                      <OverlayTrigger placement="top" overlay={<Tooltip>Reschedule</Tooltip>}>
                                      <button 
                                      disabled={!isTeleHealthAllowedTobook} 
                                      className="btn tw-text-white tw-bg-[#03335b] hover:tw-bg-[#1279BE] tw-rounded-xl me-2"
                                        onClick={e => handleTeleMedicineReschedule(e)}
                                      ><i className="bi bi-calendar4-week tw-text-lg"></i></button>
                                      </OverlayTrigger>
                                      <OverlayTrigger placement="top" overlay={<Tooltip>Cancel Booking</Tooltip>}>
                                      <button 
                                      disabled={!isTeleHealthAllowedTobook} 
                                      onClick={(e) => { setShowCancellationConfirmationModal(true) }} className={`btn tw-text-white tw-bg-[#03335b] hover:tw-bg-[#1279BE] tw-rounded-xl me-2 `}><i className="bi bi-x-lg tw-text-lg"></i></ button>
                                      </OverlayTrigger>
                                   
                                  </div>
                                )}
                                </div>
                              </ul>
                            ) : (
                              <ul className="list-group list-group-flush h-100 tw-relative tw-justify-center">
                                <>
                                  <div className="tw-absolute tw-z-[999] tw-w-full tw-top-0 tw-pt-10 tw-bg-white">
                                    <h5 className="user-card-title mb-1 text-center">
                                      <img
                                        src="https://storage.googleapis.com/ksabupatest/2025/01/28/bhgjv/s4ku84msns.png"
                                        style={{ height: "22px", marginRight: '2px', marginBottom: '2px' }}
                                      />{" "}
                                      Not Onboarded
                                    </h5>
                                  </div>

                                  {singleUserData?.actionData?.isBooked ? (
                                    <>

                                    <div>
                                      <p className="tw-mt-4 tw-text-center tw-text-[#333] tw-text-base tw-font-ibm-plex tw-px-5">
                                        The member is not yet onboarded. Below are the details of the previously booked Telehealth appointment.
                                      </p>
                                    </div>

                                    {singleUserData?.actionData?.doctorName && (
                                      <li className="list-group-item tw-border-t-0 tw-border-l-0 tw-border-r-0">
                                      <div className="row">
                                        <h3 className="user-card-subtitle col-4">

                                          Doctor Name
                                        </h3>
                                        <h3 className="user-card-subtitle col-8 tw-capitalize">
                                          <span className="tw-mr-0.5">:</span>
                                          {singleUserData?.actionData?.doctorName}
                                        </h3>
                                      </div>
                                    </li>
                                  )}

                                    {singleUserData?.actionData?.appointmentDateTime && (
                                       <li className="list-group-item tw-border-t-0 tw-border-l-0 tw-border-r-0">
                                      <div className="row">
                                        <h3 className="user-card-subtitle col-4">

                                          Appointment Date
                                        </h3>
                                        <h3 className="user-card-subtitle col-8">
                                          <span className="tw-mr-0.5">: </span>
                                          {singleUserData?.actionData?.appointmentDateTime}
                                        </h3>
                                      </div>
                                    </li>
                                  )}
                                    {singleUserData?.actionData?.thStatus && (
                                      <li className="list-group-item tw-border-t-0 tw-border-l-0 tw-border-r-0">
                                      <div className="row">
                                        <h3 className="user-card-subtitle col-4">

                                          TeleHealth Status
                                        </h3>
                                        <h3 className="user-card-subtitle col-8">
                                          <span className="tw-mr-0.5">:</span>
                                          {singleUserData?.actionData?.thStatus}
                                        </h3>
                                      </div>
                                    </li>
                                  )}
                                    {singleUserData?.actionData?.bookingTime && (
                                      <li className="list-group-item tw-border-t-0 tw-border-l-0 tw-border-r-0">
                                      <div className="row">
                                        <h3 className="user-card-subtitle col-4">

                                          Booked On
                                        </h3>
                                        <h3 className="user-card-subtitle col-8">
                                          <span className="tw-mr-0.5">:</span>
                                          {singleUserData?.actionData?.bookingTime}
                                        </h3>
                                      </div>
                                    </li>
                                  )}
                                    {singleUserData?.actionData?.bookByName && (
                                      <li className="list-group-item tw-border-t-0 tw-border-l-0 tw-border-r-0">
                                      <div className="row">
                                        <h3 className="user-card-subtitle col-4">

                                          Booked By
                                        </h3>
                                        <h3 className="user-card-subtitle col-8">
                                          <span className="tw-mr-0.5">:</span>
                                          {` ${singleUserData?.actionData?.bookByName} (${singleUserData?.actionData?.bookBy})` }

                                        </h3>
                                      </div>
                                    </li>
                                  )}
                                  </>
                                  ) : (
                                  <div>
                                    <p className="tw-mt-4 tw-text-center tw-text-[#333] tw-text-base tw-font-ibm-plex tw-px-5">
                                      This member is not onboarded in the program.
                                    </p>
                                  </div>
                                )}
                                </>

                                {singleUserData?.actionData?.isBooked ? (
                                <div className="tw-absolute tw-bottom-0 tw-p-6 tw-flex tw-justify-center tw-w-full">
                                <div className={`tw-flex tw-flex-row ${!singleUserData?.actionData?.isBooked ? "tw-w-1/2" : "tw-w-full"} tw-justify-center`}>
                                    <OverlayTrigger placement="top" overlay={<Tooltip>Upload Files</Tooltip>}>
                                      <button
                                      disabled={!isTeleHealthAllowedTobook} 
                                      onClick={e => handleUploadFilesModal(e)} className="btn tw-text-white tw-bg-[#03335b] hover:tw-bg-[#1279BE] tw-rounded-xl me-2"><i className="bi bi-upload tw-text-lg"></i></button>
                                      </OverlayTrigger>
                                      <OverlayTrigger placement="top" overlay={<Tooltip>Reschedule</Tooltip>}>
                                      <button 
                                      disabled={!isTeleHealthAllowedTobook} 
                                      className="btn tw-text-white tw-bg-[#03335b] hover:tw-bg-[#1279BE] tw-rounded-xl me-2"
                                        onClick={e => handleTeleMedicineReschedule(e)}
                                      ><i className="bi bi-calendar4-week tw-text-lg"></i></button>
                                      </OverlayTrigger>
                                      <OverlayTrigger placement="top" overlay={<Tooltip>Cancel Booking</Tooltip>}>
                                      <button 
                                      disabled={!isTeleHealthAllowedTobook} 
                                      onClick={(e) => { setShowCancellationConfirmationModal(true) }} className={`btn tw-text-white tw-bg-[#03335b] hover:tw-bg-[#1279BE] tw-rounded-xl me-2 `}><i className="bi bi-x-lg tw-text-lg"></i></ button>
                                      </OverlayTrigger>
                                   
                                  </div>
                                 </div>
                              ) : (
                                  <div className="tw-absolute tw-bottom-0 tw-p-6 tw-flex tw-justify-center tw-w-full">
                                <button className="btn btn-dark coach-call-enroll-btn text-center" onClick={e => setShowTeleHealthModal(true)}
                                  disabled={!isTeleHealthAllowedTobook ? "disabled": ''}  
                                >Telehealth Consultation
                                </button>
                                </div>
                              )}

                              </ul>
                            )
                          }
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>

              <div className="col-md-1 text-center">
                <div
                  className="agent-bg-box-grey text-center pb-2 mt-2"
                  style={{ cursor: "pointer" }}
                  data-bs-toggle="offcanvas"
                  data-bs-target="#offcanvasRightNotes"
                  aria-controls="offcanvasRightNotes"
                  onClick={(e) =>
                    handleShowcallHistoryDetailsModalFamilyNotes(
                      singleUserData.encUserId
                    )
                  }
                >
                  <img
                    src="https://storage.googleapis.com/ksabupatest/2024/09/24/go71k/d8f8z1fh7h.png"
                    className="img-fluid"
                    alt=""
                  />
                  <label style={{ fontSize: "12px" }}>Notes</label>
                </div>

                <div
                  className="agent-bg-box-grey text-center pb-2 mt-2"
                  onClick={(e) =>
                    handleShowcallHistoryDetailsModalFamily(
                      singleUserData.encUserId, "call"
                    )
                  }
                  style={{ cursor: "pointer" }}
                >
                  <img
                    src="https://storage.googleapis.com/ksabupatest/2024/09/24/h37j3/yjxakabhmu.png"
                    className="img-fluid mb-1"
                    alt=""
                  />
                  <label style={{ fontSize: "12px", lineHeight: "1" }}>
                    Call History
                  </label>
                </div>


                <div
                  className="agent-bg-box-grey text-center pb-2 mt-2"
                  onClick={(e) =>
                    handleShowcallHistoryDetailsModalFamily(
                      singleUserData.encUserId,
                      "TM"
                    )
                  }
                  style={{ cursor: "pointer" }}
                >
                  <img
                    src="https://storage.googleapis.com/ksabupatest/2025/01/31/j3dhf/y8rlw4t4t2.png"
                    className="img-fluid mb-1"
                    alt=""
                  />
                  <label style={{ fontSize: "12px", lineHeight: "1" }}>
                    TH History
                  </label>
                </div>


                {/* <button
                  className="agent-card-subtitle1 btnn call-now-btn mt-3"
                  style={{ cursor: "pointer" }}
                  onClick={e => refrshMember()}
                >
                  <i class='bx bx-refresh' style={{ ...(requestStatus.isRefreshLoading && spinStyle), fontSize: '25px' }}></i>
                </button> */}

                <div
                  className="offcanvas offcanvas-end box-script-ui"
                  tabIndex="-1"
                  id="offcanvasRightEng11"
                  aria-labelledby="offcanvasRightEng1Label"
                  data-bs-backdrop="static"
                >
                  <div className="offcanvas-header pt-2 pb-1"></div>
                  <div className="offcanvas-body py-0">
                    <div className="row">
                      <textarea
                        className="col mb-3"
                        value={callScripts.english.script}
                        rows="22"
                        style={{
                          textAlign: "justify",
                          border: "0",
                          backgroundColor: "transparent",
                        }}
                        disabled
                      ></textarea>
                      <div className="col-auto">
                        <button
                          type="button"
                          className="btn btn-dark coach-call-enroll-btn"
                          data-bs-dismiss="offcanvas"
                          aria-label="Close"
                        >
                          Close
                        </button>
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  className="offcanvas offcanvas-end box-script-ui"
                  // style={{width:"30%"}}
                  tabIndex="-1"
                  id="offcanvasRightArb11"
                  aria-labelledby="offcanvasRightArb1Label"
                  data-bs-backdrop="static"
                >
                  <div
                    className="offcanvas-header pt-2 pb-1 text-end"
                    style={{ justifyContent: "flex-end" }}
                  ></div>
                  <div className="offcanvas-body py-0">
                    <div className="row">
                      <textarea
                        className="col mb-3"
                        rows="22"
                        value={callScripts.arabic.script}
                        style={{
                          textAlign: "right",
                          border: "0",
                          backgroundColor: "transparent",
                        }}
                        disabled
                      ></textarea>
                      <div className="col-auto">
                        <button
                          type="button"
                          className="btn btn-dark coach-call-enroll-btn"
                          data-bs-dismiss="offcanvas"
                          aria-label="Close"
                        >
                          Close
                        </button>
                      </div>
                    </div>
                  </div>
                </div>

                {/*----------- call notes -------------------*/}
                <div
                  className="offcanvas offcanvas-end box-script-ui box-script-ui-width100 "
                  tabIndex="-1"
                  id="offcanvasRightNotes"
                  aria-labelledby="offcanvasRightNotesLabel"
                  // data-bs-backdrop="static"
                  data-bs-backdrop="static"
                >
                  {/* <div className="tw-fixed tw-inset-0 tw-bg-black/45"> */}
                  <div className="">
                    <div
                      className="offcanvas-header pt-2 pb-1 tw-bg-white"
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <div
                        style={{ width: "90%" }}
                        className="d-flex align-items-center"
                      >
                        <h1 className="modal-title me-4">
                          {singleUserData.memberName}
                        </h1>
                      </div>
                      <button
                        type="button"
                        className="btn btn-dark coach-call-enroll-btn"
                        data-bs-dismiss="offcanvas"
                        aria-label="Close"
                        onClick={(e) => setCallNotesErrors("")}
                      >
                        Close
                      </button>
                    </div>
                    <div className="offcanvas-body py-0 tw-bg-white">
                      <div className="row">
                        <div className="col-12 mt-2">
                          <div
                            className="table-responsive"
                            style={{ height: "350px", overflowY: "scroll" }}
                          >
                            <table className="table mb-0 ">
                              <thead
                                style={{ position: "sticky", top: "0" }}
                              >
                                <tr>
                                  <th scope="col" style={{ width: "35%" }}>
                                    Agent Name{" "}
                                  </th>
                                  <th scope="col" style={{ width: "50%" }}>
                                    Notes
                                  </th>
                                  <th scope="col" style={{ width: "50%" }}>
                                    Date
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                {callNotesList &&
                                  callNotesList.length > 0 &&
                                  callNotesList.map((note, index) => (
                                    <tr key={index}>
                                      <td>{note.agentName}</td>
                                      <td>{note.notes}</td>
                                      <td>{note.date}</td>
                                    </tr>
                                  ))}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="offcanvas-footer px-3 py-3 pt-2 tw-bg-white">
                      <div className="row">
                        <div className="col-12">
                          <textarea
                            className="input-field-ui"
                            rows="3"
                            name="callNotes"
                            value={callNotes}
                            onChange={(e) => changeCallNotes(e)}
                            required
                          ></textarea>
                          {callNotesErrors && (
                            <p className="text-danger">{callNotesErrors}</p>
                          )}

                          {callNotesSubmissionMessage && (
                            <p className="text-success">
                              {callNotesSubmissionMessage}
                            </p>
                          )}
                        </div>

                        <div className="col-12">
                          <button
                            type="button"
                            className="btn btn-dark coach-call-enroll-btn"
                            onClick={(e) =>
                              saveCallNotes(e, singleUserData.encUserId)
                            }
                          >
                            Save
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {/* </div> */}
                {/* </div> */}
                {/* </div> */}
              </div>
            </div>
          </div>
          <div className="modal-footer" style={{ justifyContent: "center" }}>
            {familyCallReportActionCompleted[singleUserData.memberId] &&
              singleUserData.enrollmentStatus === "inactive" &&
              familyCallReportAction[singleUserData.memberId] !== "call_back" ? (
              <button
                ref={closeMockModal}
                type="button"
                className="btn btn-secondary clear-btn"
                onClick={(e) => setCallCloseWarningPopup(true)}
              >
                Close
              </button>
            ) : (
              <button
                ref={closeMockModal}
                type="button"
                className="btn btn-secondary clear-btn"
                data-bs-dismiss="modal"
                disabled={
                  (callStatus !== "idle" ||
                    (familyCallReportActionCompleted[singleUserData.memberId] &&
                      singleUserData.enrollmentStatus === "inactive" &&
                      familyCallReportAction[singleUserData.memberId] !==
                      "call_back")) &&
                  "disbaled"
                }
                onClick={(e) => mockCallPopupClose(e)}
              >
                Close
              </button>
            )}
          </div>
        </div>
      </div>
      <audio ref={callAudio} id="remote-audio" autoPlay></audio>
     
      {
        showteleHealthModal && (
          <div className="tw-fixed tw-inset-0 tw-bg-black/45 tw-z-[9999]">
        <TeleHealthBook showTelehealthModal={showteleHealthModal} closeTelehealthModal={closeTelehealthModal} handleShowUploadTeleHealthReschedule={() => { }} callCallsHistory={(data) => { handlePostTeleHealthBooking(data) }} membershipNo={singleUserData['memberId']} userId={singleUserData.encUserId} from={"memberconnect"}
          fetchDoctorList={fetchDoctorList} fetchDoctorSlots={fetchDoctorSlots} bookMemberAppointment={bookMemberAppointment} memberDetailsEdge={memberDetailsEdge} rescheduleCall={rescheduleAppointment ? rescheduleAppointment : null} doctorName={rescheduleAppointment ? doctorName : null} updateTeleHealthService={rescheduleAppointment ? cancel_or_reschedule_appointment : function () { }} teleCallId={rescheduleAppointment ?(remoteAppointmentData.appointmentId !== ""? remoteAppointmentData.appointmentId: singleUserData?.actionData?.appointmentId)|| null : null} bookingId={rescheduleAppointment ?(remoteAppointmentData.bookingId !== ""? remoteAppointmentData.bookingId  : singleUserData?.actionData?.bookingId )|| null : null} confNumber={conferenceNumber} universalConfNumber={universalConfNumber} fromTab="telehealth"
        />
        </div>
      )}

      {
        showcallHistoryDetailsModal &&
          <div className="tw-fixed tw-inset-0 tw-bg-black/45 tw-z-[9999]">
        <BsModal id="callHistoryDetails" show={showcallHistoryDetailsModal} onHide={() => setShowcallHistoryDetailsModal(false)} centered size="xl" backdrop="static" keyboard={false} fullscreen={false}>
          <BsModal.Header closeButton>
            <h1 className='modal-title me-4 tw-text-2xl'>{singleUserData.memberName}</h1>
          </BsModal.Header>
          <BsModal.Body>
            <div className='row'>

              {

                <div className="col-12 mb-5">
                  <h4 className="tw-text-lg">Call History</h4>
                  <div className="table-responsive" style={{ height: memberCallList && memberCallList.length > 0 ? 'auto' : '420px', overflowY: "scroll" }}>
                    <table className="table mb-0">
                      <thead>
                        <tr>
                        <th scope="col">Date</th>
                          <th scope="col">Agent Name</th>
                          <th scope="col">Onboarding Status</th>
                          <th scope="col">Call Duration</th>
                          <th scope="col">Call Status</th>
                          
                        </tr>
                      </thead>
                      <tbody>
                        {memberCallHistory && memberCallHistory?.map((item, index) => (
                          <tr key={index}>
                            <td>{item.callDate || ""}</td>
                            <td>{item.agentName || ""}</td>
                            <td>{item.enrollStatus || ""}</td>
                            <td>{item.callDuration || ""}</td>
                            <td>{item.callStatus || ""}</td>
                          </tr> 
                        ))
                        }
                      </tbody>
                    </table>
                  </div>
                </div>
              }

              {
                memberCallList && memberCallList.length > 0 &&
                <>
                  <div className="col-12">
                    <h4>Call History</h4>
                    <div className="table-responsive" style={{ height: "420px", overflowY: "scroll" }}>
                      <table className="table mb-0">
                        <thead>
                          <tr>
                            <th scope="col">Date</th>
                            <th scope="col">Agent Name</th>
                            <th scope="col">Onboarding Status</th>
                            <th scope="col">Call Duration</th>
                            <th scope="col">Call Status</th>
                            <th scope="col">Data Rating </th>
                            <th scope="col">Reason</th>
                            <th scoe="col">Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {memberCallList?.map((item, index) => (
                            <tr key={index}>
                              <td>{item.placedCallDate}</td>
                              <td>{item.agentName}</td>
                              <td>{item.enrollStatus}</td>
                              <td>{item.callDuration}</td>
                              <td>{item.callStatus}</td>
                              <td>{item.dataRating || "-"}</td>
                              <td>{item.reason || "-"}</td>
                            </tr>
                          ))
                          }
                        </tbody>
                      </table>
                    </div>
                  </div></>
              }
            </div>
          </BsModal.Body>
        </BsModal>
          </div>
      }

      {
        showTmHistoryModal &&
        <div className="tw-fixed tw-inset-0 tw-bg-black/45 tw-z-[9999]">
        <BsModal id="tmHistoryDetails" show={showTmHistoryModal} onHide={() => setShowTmHistoryModal(false)} centered size="xl" backdrop="static" keyboard={false} fullscreen={false}>
          <BsModal.Header style={{ paddingLeft: "-5px" }} closeButton>
            {/* <label className="col-form-label pt-0 me-4"> <i className="me-2"><img src="https://storage.googleapis.com/ksabupatest/2024/09/24/nuf9q/dhb4tjf45a.png" style={{height:"14px", verticalAlign:"baseline"}} alt="" /></i>HOF: Irfan Khan</label> */}
              <h4 className='modal-title me-4 tw-text-left tw-w-[90%] -tw-ml-[90px]'>{singleUserData.memberName}</h4>
          </BsModal.Header>
          <BsModal.Body>
            <div className='row'>
              <div className="col-12">
                <h4 className="tw-text-lg">TH History</h4>
                <div className="table-responsive" style={{ height: "420px", overflowY: "scroll" }}>
                  <table className="table mb-0">
                    <thead>
                      <tr>
                        <th scope="col">Appointment Date</th>
                        <th scope="col">Doctor Name</th>
                        <th scope="col">Status</th>
                        <th scope="col">Booked By</th>
                        <th scope="col">Notes</th>
                      </tr>
                    </thead>
                    <tbody>
                      {tmHistory?.map((item, index) => (
                        <tr key={index}>
                          <td>{item.appointmentDateTime}</td>
                          <td>{item.doctorName}</td>
                          <td>{item.edgeStatus}</td>
                          <td>{` ${item.bookByName} ( ${item.bookBy})` || ""}</td>
                          <td>{item.notes || ""}</td>
                        </tr>
                      ))
                      }
                    </tbody>
                  </table>
                </div>
              </div>
              
            </div>
          </BsModal.Body>
        </BsModal>
        </div>
      }

      {/* ----------------------------- upload tele health modal =-------------------------------j */}
      {
        showUploadTelehealthModal &&
        <div className="tw-fixed tw-inset-0 tw-bg-black/45 tw-z-[9999]">
        <BsModal id="externalMemberSearch" show={showUploadTelehealthModal} onHide={setShowUploadTeleHealthModal} size="xl" backdrop="static" keyboard={false} fullscreen={false}>
          <BsModal.Header closeButton>
            <h1 className='modal-title'>Attach Reports</h1>
          </BsModal.Header>
          <BsModal.Body>
            <div className='table-responsive UploadTeleHealthBookTable' style={{ borderRadius: '15px' }}>
              { 
                files.length > 0 ?
                <DataTable
                  columns={uploadColumns}
                  data={files}
                  pagination={false}
                  striped
                  responsive
                  noHeader
                  noFooter
                  customStyles={customStyles}
                />: <div className="tw-flex tw-justify-center tw-items-center tw-h-[320px]">
                  <p>The health locker for this member has no reports.</p>
                </div>
            }
            </div>

            {

              showButton &&
              <div className="col-md-12 text-center mt-2">
                {buttonLoading ? (
                  <img src="https://storage.googleapis.com/ksabupatest/2024/09/24/3lsab/y968s2s843.gif" style={{ height: "35px" }} alt="Loading..." />
                ) : (
                  <button className='btn program-save-btn me-2' onClick={handleUPloadFile}>Upload</button>
                )}
              </div>

            }
          </BsModal.Body>
        </BsModal>
        </div>
      }

      {/* ---------------------------------- Cancellation Confirmation Modal ------------------------------- */}
      {
        showCancellationConfirmationModal &&

        <div className="tw-fixed tw-inset-0 tw-bg-black/45 tw-z-[9999]">
        <BsModal id="cancel-confirmationModl" show={showCancellationConfirmationModal} onHide={e => setShowCancellationConfirmationModal(false)} backdrop="static"
          keyboard="false" fullscreen={false} centered >

          <BsModal.Header closeButton>
            <BsModal.Title
              style={{
                width: "90%",
                color: "black",
                textAlign: "center",
              }}
            >
              Confirm
            </BsModal.Title>
          </BsModal.Header>
          <hr />
          <BsModal.Body >
            <div className="row">
              <div className="col-md-12 text-center">
                <h5 className="main-subtitle ">Do you want to cancel Telehealth consultation ?</h5>
              </div>
              <div className="col-md-12 my-3 text-center">
                <button className='btn program-save-btn me-2'
                  onClick={e => handleCancelAppointment()}
                >OK</button>
              </div>
            </div>
          </BsModal.Body>

        </BsModal>
        </div>
      }

      { 
        showMockDispositionModal && 
        <div className="tw-fixed tw-inset-0 tw-bg-black/45 tw-z-[9999]">
        <BsModal show={showMockDispositionModal} onHide={() => { setShowMockDispositionModal(false); setCallReportAction(""); setReflect(!reflect) }} centered backdrop="static">
        <BsModal.Header closeButton></BsModal.Header>
        <BsModal.Body className="pt-0">
          <div className="disposition_from">
            <div className="">

              <div className="call-status mb-4">
                <h2 className="module-card-title mb-2">Call Status</h2>
                <div className="form-check form-check-inline">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="callStatus"
                    id="call_status"
                    value="completed"
                    onChange={e => handleMockDispositionChange(e)}

                  />
                  <label className="form-check-label" htmlFor="inlineRadio3">
                    Completed
                  </label>
                </div>

                <div className="form-check form-check-inline">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="callStatus"
                    id="call_status2"
                    value="no-answer"
                    onChange={e => handleMockDispositionChange(e)}


                  />
                  <label className="form-check-label" htmlFor="inlineRadio4">
                    No-answer
                  </label>



                </div>

                <div className="form-check form-check-inline">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="callStatus"
                    id="dataRating2"
                    value="busy"
                    onChange={e => handleMockDispositionChange(e)}
                  />

                  <label className="form-check-label" htmlFor="inlineRadio4">
                    Busy
                  </label>
                </div>

                <div className="form-check form-check-inline">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="callStatus"
                    id="dataRating2"
                    value="failed"
                    onChange={e => handleMockDispositionChange(e)}
                  />

                  <label className="form-check-label" htmlFor="inlineRadio4">
                    Failed
                  </label>
                </div>

              </div>




              <div className="mt-3">
                <button className="btn btn-success coach-call-enroll-btn" onClick={e => saveMockDisposition(e)}
                  disabled={requestStatus.isDispositionSaveLoading ? "disabled" : ""}
                >
                  Save
                </button>
              </div>
            </div>
          </div>

        </BsModal.Body>
        </BsModal>
        </div>
      }


    </div>
  );
});

export default CallModal;
