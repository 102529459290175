import React, { useState,useEffect } from "react";
import "react-datepicker/dist/react-datepicker.css";
import CalendarDatePicker from "../CalendarDatePicker";
import { member_insight_summery_data, fetch_member_agent_list,fetchAgentWhatsappData, agent_telehealth_graph } from "../../../services/met/Service";
import { LoaderLoader, formatDate } from "../../utils/CommonLibrary";
import moment from "moment";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import MembersReachedBar from "../DashboardGraphsMCI/MembersReachedBar";
import CallsMadedBar from "../DashboardGraphsMCI/CallsMadedBar";
import MembersEnrolledBar from "../DashboardGraphsMCI/MembersEnrolledBar";
import AgentsBar from "../DashboardGraphsMCI/AgentsBar";
import TargetAchievementBar from "../DashboardGraphsMCI/TargetAchievementBar";
import CallDispositionBar from "../DashboardGraphsMCI/CallDispositionBar";
import DataQualityPie from "../DashboardGraphsMCI/DataQualityPie";
import MemberConversionFunnelBar from "../DashboardGraphsMCI/MemberConversionFunnelBar";
import AgeGroupConversionBar from "../DashboardGraphsMCI/AgeGroupConversionBar";
import MemberCorrectDataFunnelBar from "../DashboardGraphsMCI/MemberCorrectDataFunnelBar";
import MemberIncorrectDataFunnelBar from "../DashboardGraphsMCI/MemberIncorrectDataFunnelBar";
import CallConversionDispositionBar from "../DashboardGraphsMCI/CallConversionDispositionBar";
import { decryptData } from "../../utils/Utils";
import MembesAccrossPathwayMonthsBar from "../DashboardGraphsCNP/MembesAccrossPathwayMonthsBar";
import MemberPathwayStatusPie from "../DashboardGraphs/MemberPathwayStatusPie";


function ContentCallHistory() {

    const storedDates = localStorage.getItem('selectedDates') ? JSON.parse(decryptData(localStorage.getItem('selectedDates'))) : '';

    const [startDate, setStartDate] = useState(
        storedDates && storedDates.length === 4 ? moment(storedDates[0]) : moment()
    );
    const [endDate, setEndDate] = useState(
        storedDates && storedDates.length === 4 ? moment(storedDates[1]) : moment()
    );

    const [agentData, setAgentData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const[stateAgentId,setStateAgentId] = useState('all');
    const [loading, setLoading] = useState(false);
    

    const [seriesGraph, setSeriesGraph] = useState({
        whatsAppSent: [],
        whatsAppDelivered: [],
        whatsAppRead: [],
        telehealthPie: []
    })

     const [seriesGraphNew, setSeriesGraphNew] = useState({
        telehealthPie: [],
        telehealthColumn: [],
    })

    const today = new Date();
    const sevenDaysAgo = new Date();
    sevenDaysAgo.setDate(today.getDate());

    const handleDateChange = ({ start, end }) => {
        setStartDate(start);
        setEndDate(end);
    };

    const [avgCallTime,setAvgCallTime] = useState(0);
    const [attemptCallTotal,setAttemptCallTotal] = useState(0);
    const [firstCallConversionRate,setFirstCallConversionRate] = useState(0);
    const [memberSatisfaction,setMemberSatisfaction] = useState(0);
    const [agentUtilizationRate,setAgentUtilizationRate] = useState(0);
    const [totalIncorrectData,setTotalIncorrectData] = useState(0);
    const [totalIncorrectDataPercentage,setTotalIncorrectDataPercentage] = useState(0);
    const [totalMemberUntoched,setTotalMemberUntoched] = useState(0);
    const [statsValue, setStatsValue ] = useState({'membersMigrated':'','touchedCount':'','untouchedCount':'','validCount':'','invalidCount':'','onboardedCount':'',
        'extraEntryCount':'','notInterstedCount':'','backPoolCount':'','onboardLaterCount':'','totalOnboardCount':'','telehealthCount':'0',
        'telehealthBooking':'0','telehealthConfirmed':'0','telehealthCompleted':'0','telehealthCancelled':'0','telehealthReschedule':'0','telehealthOnboarded':'0'
    });
    const [statsValueWhatsApp, setStatsValueWhatsApp] = useState({
        'whatsAppSentCount': '', 'whatsAppeliveredCount': '', 'whatsAppReadCount': ''
    });

    
    useEffect(() => {
        fetchAgentUserlist();
    }, [stateAgentId]);

    useEffect(() => {
        setLoading(true);
        agentInsightStats();
        agentWhatsAppStats();
        agentTelehealthQualityGraph();
    }, [stateAgentId,startDate,endDate]);


    const agentWhatsAppStats = async () => {
        await fetchAgentWhatsappData(formatDate(startDate), formatDate(endDate), stateAgentId, 'admin')
            .then((response) => {
                console.log('response whatsapp',response.data.data.whatsAppSent);
                const responseData = response.data.data;
                setStatsValueWhatsApp(prevState => ({ ...prevState, whatsAppSentCount: responseData.whatsAppSent.totalValue }));
                setSeriesGraph(prevState => ({
                    ...prevState, whatsAppSent: responseData.whatsAppSent
                }));
                setLoading(false);
            });
    }

    const fetchAgentUserlist = async () =>{
        setIsLoading(true); //to show loader
    try {
            let resp = await fetch_member_agent_list();
            setAgentData(resp.data.data);
        } catch (error) {
            console.error(error);
        }
        finally {
            setIsLoading(false);  // Will be called regardless of success or failure
        }
    }

    const agentInsightStats = async () =>{
        setIsLoading(true);     
        try {
            let sdate= formatDate(startDate);
            let edate= formatDate(endDate);
            let resp = await member_insight_summery_data(sdate,edate,stateAgentId,'admin');
            if(resp.data.code === 200){
                setAvgCallTime(resp.data.data.recordAvgDuration);
                setAttemptCallTotal(resp.data.data.attemptPerMember);
                setMemberSatisfaction(resp.data.data.memberSatisfaction);
                setFirstCallConversionRate(resp.data.data.firstCallConversionRate);
                setAgentUtilizationRate(resp.data.data.agentUtilizationRate);
                setTotalIncorrectData(resp.data.data.totalIncorrectData);
                setTotalIncorrectDataPercentage(resp.data.data.totalIncorrectData);
                setTotalMemberUntoched(resp.data.data.totalMemberUntoched);
                setStatsValue(prevState => ({ ...prevState, membersMigrated: resp.data.data.successRecords, touchedCount: resp.data.data.touchCount, untouchedCount: resp.data.data.untouchCount,
                    validCount: resp.data.data.valid, invalidCount: resp.data.data.invalid, onboardedCount: resp.data.data.onboardedValue, notInterstedCount: resp.data.data.rejected, onboardLaterCount: resp.data.data.laterOnboard,
                    totalOnboardCount: resp.data.data.onboardedTotal,backPoolCount: resp.data.data.backToPool,poolExaust: resp.data.data.poolExaust,extraEntryCount: resp.data.data.extraManualOnboarded,agentExtraManualEntryCount:resp.data.data.agentExtraManualEntryCount,
                    telehealthCount: resp.data.data.telehealthCount,telehealthBooking: resp.data.data.telehealthBooking,telehealthConfirmed: resp.data.data.telehealthConfirmed,telehealthCompleted: resp.data.data.telehealthCompleted,telehealthCancelled: resp.data.data.telehealthCancelled,
                    telehealthReschedule: resp.data.data.telehealthReschedule,telehealthOnboarded: resp.data.data.telehealthOnboarded
                }));
            }
        } catch (error) {
            console.error('Error:', error);
        }finally{ 
            setIsLoading(false); 
        }
    }

const agentTelehealthQualityGraph = async () => {
        try {
            let sdate = formatDate(startDate);
            let edate = formatDate(endDate);
            let response = await agent_telehealth_graph(sdate,edate,stateAgentId,'admin');
            if(response.data.code === 200){
                setSeriesGraphNew(prevState => ({
                    ...prevState, telehealthPie: response.data.data.telehealthPie.series.data, telehealthColumn: response.data.data.telehealthColumn}));
            }
        } catch (error) {
            console.log(error);
        } 
    }


    const renderMCI6Tooltip = (props) => (
        <Tooltip className="custom-tooltip" {...props}>
            Number of members who were successfully reached.
        </Tooltip>
    );

    const renderMCI7Tooltip = (props) => (
        <Tooltip className="custom-tooltip" {...props}>
            Total number of calls made to members.
        </Tooltip>
    );

    const renderMCI9Tooltip = (props) => (
        <Tooltip className="custom-tooltip" {...props}>
            Total number of agents reaching out to members.
        </Tooltip>
    );

    const renderMCI11Tooltip = (props) => (
        <Tooltip className="custom-tooltip" {...props}>
            Dispositions of call status marked by agents after each call.
        </Tooltip>
    );

    const renderMCI12Tooltip = (props) => (
        <Tooltip className="custom-tooltip" {...props}>
            Average time spent on each call by agents over time.
        </Tooltip>
    );

    const renderMCI13Tooltip = (props) => (
        <Tooltip className="custom-tooltip" {...props}>
            Average number of call attempts per member.
        </Tooltip>
    );

    const renderMCI14Tooltip = (props) => (
        <Tooltip className="custom-tooltip" {...props}>
            Percentage of members enrolled after the first call attempt.
        </Tooltip>
    );

    const renderMCI15Tooltip = (props) => (
        <Tooltip className="custom-tooltip" {...props}>
            Measure of member satisfaction based on feedback.
        </Tooltip>
    );

    const renderMCI16Tooltip = (props) => (
        <Tooltip className="custom-tooltip" {...props}>
            Percentage of time agents spend on calls versus available time.
        </Tooltip>
    );

    const renderMCI18Tooltip = (props) => (
        <Tooltip className="custom-tooltip" {...props}>
           Stages of member conversion from contact to enrollment.
        </Tooltip>
    );

    const renderMCI19Tooltip = (props) => (
        <Tooltip className="custom-tooltip" {...props}>
           Conversion rate of onboarded members segmented by age groups.
        </Tooltip>
    );


    const renderMCI17Tooltip = (props) => (
        <Tooltip className="custom-tooltip" {...props}>
           Total number of TeleHealth consultations across all statuses, including Confirmed, Completed, Canceled, and Rescheduled.
        </Tooltip>
    );

    const renderMCI23Tooltip = (props) => (
        <Tooltip className="custom-tooltip" {...props}>
            Total telehealth appointments successfully confirmed.
        </Tooltip>
    );

    const renderMCI24Tooltip = (props) => (
        <Tooltip className="custom-tooltip" {...props}>
            Number of telehealth appointments successfully completed.
        </Tooltip>
    );

    const renderMCI20Tooltip = (props) => (
        <Tooltip className="custom-tooltip" {...props}>
            Number of telehealth appointments that were canceled.
        </Tooltip>
    );

    const renderMCI21Tooltip = (props) => (
        <Tooltip className="custom-tooltip" {...props}>
            Number of telehealth appointments that were rescheduled.
        </Tooltip>
    );

    const renderMCI22Tooltip = (props) => (
        <Tooltip className="custom-tooltip" {...props}>
            Total number of members onboarded the first time for telehealth services that have the status as confirmed or completed.
        </Tooltip>
    );


    //call back agent graph
    const [totalAgentsCount,setTotalAgentsCount] = useState(0);
    const handleAgentCallback = (childData) => {
        setTotalAgentsCount(childData);
      };

    //call back members enroll graph
    const [totalEnrollCount,setTotalEnrollCount] = useState(0);
    const handleEnrollCallback = (childData) => {
            setTotalEnrollCount(childData);
    };
    
    //call back for member reached graph
    const [totalReachedCount,setTotalReachedCount] = useState(0);
    const handleReachedCallback = (childData) => {
        setTotalReachedCount(childData);
    };

    //call back for call made graph
    const [totalCallMadeCount,setTotalCallMadeCount] = useState(0);
    const handleCallMadeCallback = (childData) => {
        setTotalCallMadeCount(childData);
    };

    //call back for call conversion graph
    
    const [totalCallConversionData,setTotalCallConversionData] = useState({ count_enroll: 0, count_reject: 0,count_later: 0 });
    const handleCallConversionCallback = (childData) => {
        setTotalCallConversionData(childData);
    };
    //member conversion funnel call back
    const [totalAgentMemberConversionData,setTotalAgentMemberConversionData] = useState(0);
    const handleAgentMemberCallback = (childData) => {
        setTotalAgentMemberConversionData(childData);
    };
      
    //Target Achieved call back
    const [totalTargetAchieved,setTotalTargetAchieved] = useState(0);
    const handleTargetAchievedCallback = (childData) => {
        setTotalTargetAchieved(childData);
    };

    return (
        <>
         <LoaderLoader isLoading={isLoading} />
         <section className='ContentMemberEngagement'>
            <div className="container-fluid px-0 mb-5 pb-4 ">
                <div className="row align-items-center pb-3 sticky-top-bar">
                    <div className="col-md text-start">
                        <h1 className="main-title">MemberConnect Insights</h1>
                    </div>
                    <div className="col-md-3">
                        <CalendarDatePicker startDate={startDate} endDate={endDate} onDateChange={handleDateChange} />
                    </div>
                </div>
                <div className='reports-programs mb-3'>
                    <div className="row align-items-center">
                        <div className="col-md-auto">
                            <img src="https://storage.googleapis.com/ksabupatest/2024/09/24/h3glw/rofjblbhs8.png" alt="Invited Members icon" style={{height:"140px"}} className="img-fluid mb-0"/>
                        </div>
                        <div className="col-md">
                            <p className="mb-0">Provides admins with the tools to manage agent metrics, including call statistics, attendance, performance, utilization, and detailed call data, ensuring comprehensive oversight and optimization.</p>
                        </div>
                    </div>
                </div>
                
                <div className="member-engagement p-3 mb-3">
                    <div className="row align-items-center mb-2">
                        <div className="col">
                            <h1 className='dashboard-graph-title' style={{justifyContent:"flex-start"}}>Reaching out members
                            <OverlayTrigger
                                                        placement="top"
                                                        overlay={<Tooltip className="custom-tooltip">Overview of the status breakdown for member outreach.</Tooltip>}
                                                    >
                                                        <i className='bx bx-info-circle ms-1'></i>
                                                    </OverlayTrigger>
                            </h1>
                        </div>
                    </div>
                    <div className="row text-center px-2">
                        <div className="col" >
                            <div className="row">
                            <div className="col-2 px-1" >
                                                    <div className={`card bg-grey`} style={{ borderRadius: '14px', border: 'none', border: '2px solid #1279be', }}>
                                                        <div className="card-body p-2 d-flex align-items-center">
                                                            <div className="" style={{ "width": "100%" }}>
                                                                <div className="col">
                                                                <div className="row">
                                                <div className="col">
                                                    <h1 className="card-title1">{statsValue.membersMigrated}</h1>
                                                    <h5 className="card-text1">Members Migrated
                                                    <OverlayTrigger
                                                        placement="top"
                                                        overlay={<Tooltip className="custom-tooltip">Members successfully migrated by the RCM team.</Tooltip>}
                                                    >
                                                        <i className='bx bx-info-circle ms-1'></i>
                                                    </OverlayTrigger>
                                                    </h5>
                                                </div>
                                            </div>
                                                                    <hr />
                                                                    <div className="row" style={{ width: "100%" }}>
                                                                        <div className="col">
                                                                        <h1 className="card-title1">{statsValue.agentExtraManualEntryCount}</h1>
                                                                            <h5 className="card-text1">Extra Manual Entries
                                                                                <OverlayTrigger placement="top"
                                                                                    overlay={<Tooltip className="custom-tooltip">Members manually uploaded into the system by Agents</Tooltip>}>
                                                                                    <i className='bx bx-info-circle ms-1'></i>
                                                                                </OverlayTrigger>
                                                                            </h5>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                {/* <div className="col-2 px-1" >
                                    <div className={`card bg-grey`} style={{ borderRadius: '14px', border: 'none', border: '2px solid #1279be' }}>
                                        <div className="card-body p-2">
                                            <div className="row">
                                                <div className="col">
                                                    <h1 className="card-title1">{statsValue.membersMigrated}</h1>
                                                    <h5 className="card-text1">Members Migrated
                                                    <OverlayTrigger
                                                        placement="top"
                                                        overlay={<Tooltip className="custom-tooltip">Members successfully migrated by the RCM team.</Tooltip>}
                                                    >
                                                        <i className='bx bx-info-circle ms-1'></i>
                                                    </OverlayTrigger>
                                                    </h5>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div> */}

                                <div className="col-auto  px-1" >
                                    <div className={`card bg-grey mb-1`} style={{ borderRadius: '14px', border: '2px solid #1279be', height: "90px" }}>
                                        <div className="card-body p-2 d-flex align-items-center">
                                            <div className="row">
                                                <div className="col">
                                                    <h1 className="card-title1">{statsValue.touchedCount}</h1>
                                                    <h5 className="card-text1">Members Reached
                                                    <OverlayTrigger
                                                        placement="top"
                                                        overlay={<Tooltip className="custom-tooltip">Number of unique members that were reached.(Valid + Incorrect)</Tooltip>}
                                                    >
                                                    <i className='bx bx-info-circle ms-1'></i>
                                                    </OverlayTrigger>
                                                    </h5>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>

                                <div className="col-auto  px-1" >
                                    <div className={`card bg-grey mb-1`} style={{ borderRadius: '14px', border: '2px solid #1279be', height: "90px" }}>
                                        <div className="card-body p-2 d-flex align-items-center">
                                            <div className="row">
                                                <div className="col">
                                                    <h1 className="card-title1">{statsValue.validCount}</h1>
                                                    <h5 className="card-text1">Valid
                                                    <OverlayTrigger 
                                                        placement="top"
                                                        overlay={<Tooltip className="custom-tooltip">Members eligible for outreach who have been successfully contacted or onboarded.</Tooltip>}
                                                    >
                                                        <i className='bx bx-info-circle ms-1'></i>
                                                    </OverlayTrigger>
                                                    </h5>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className={`card bg-grey`} style={{ borderRadius: '14px', border: 'none' }}>
                                        <div className="card-body p-2">
                                            <div className="row">
                                                <div className="col">
                                                    <div className="row">
                                                        <div className="col">
                                                            <h1 className="card-title1">{statsValue.invalidCount}</h1>
                                                            <h5 className="card-text1">Incorrect
                                                            <OverlayTrigger 
                                                                placement="top"
                                                                overlay={<Tooltip className="custom-tooltip">Members marked incorrect by the agent due to data issues, such as policy termination, data inaccuracies or discrepancies.</Tooltip>}
                                                            >
                                                                <i className='bx bx-info-circle ms-1'></i>
                                                            </OverlayTrigger>
                                                            </h5>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="col" >
                                    <div className="row">
                                    <div className="col-12 px-1 mb-1" >
                                        <div className={`card bg-grey`} style={{ borderRadius: '14px', border: 'none', height:"100%",border: '2px solid #1279be', height: "90px" }}>
                                        <div className="card-body p-2 d-flex align-items-center">
                                        <div className="row" style={{ "width": "100%" }}>
                                                        <div className="col border-end">
                                                            <div className="row">
                                                                <div className="col">
                                                                    <h1 className="card-title1">{statsValue.onboardedCount}</h1>
                                                                    <h5 className="card-text1">Onboarded
                                                                    <OverlayTrigger 
                                                                        placement="top"
                                                                        overlay={<Tooltip className="custom-tooltip">Number of members added in the program.</Tooltip>}
                                                                    >
                                                                        <i className='bx bx-info-circle ms-1'></i>
                                                                    </OverlayTrigger>
                                                                    </h5>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col">
                                                        <div className="row">
                                                            <div className="col">
                                                                <h1 className="card-title1">{statsValue.extraEntryCount}</h1>
                                                                <h5 className="card-text1">Extra Manual Onboarded
                                                                <OverlayTrigger 
                                                                        placement="top"
                                                                        overlay={<Tooltip className="custom-tooltip">Number of members onboarded through add member feature by agents.</Tooltip>}
                                                                    >
                                                                        <i className='bx bx-info-circle ms-1'></i>
                                                                    </OverlayTrigger>
                                                                </h5>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                        </div>
                                    </div>
                                </div>
                                        <div className="col-12 px-1 mb-2" >
                                            <div className={`card bg-grey`} style={{ borderRadius: '14px', border: 'none', height:"100%" }}>
                                                <div className="card-body p-2">
                                                <div className="row">
                                                    <div className="col-3 border-end">
                                                        <div className="row">
                                                            <div className="col">
                                                                <h1 className="card-title1">{statsValue.notInterstedCount}</h1>
                                                                <h5 className="card-text1">Not Interested
                                                                <OverlayTrigger 
                                                                        placement="top"
                                                                        overlay={<Tooltip className="custom-tooltip">Members who are not interested in the program.</Tooltip>}
                                                                    >
                                                                        <i className='bx bx-info-circle ms-1'></i>
                                                                    </OverlayTrigger>
                                                                </h5>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="col-3 border-end">
                                                        <div className="row">
                                                            <div className="col">
                                                                <h1 className="card-title1">{statsValue.backPoolCount}</h1>
                                                                <h5 className="card-text1">Back to pool
                                                                <OverlayTrigger 
                                                                        placement="top"
                                                                        overlay={<Tooltip className="custom-tooltip">Members who can be contacted again for onboarding.</Tooltip>}
                                                                    >
                                                                        <i className='bx bx-info-circle ms-1'></i>
                                                                    </OverlayTrigger>
                                                                </h5>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="col-3 border-end">
                                                        <div className="row">
                                                            <div className="col">
                                                                <h1 className="card-title1">{statsValue.poolExaust}</h1>
                                                                <h5 className="card-text1 tw-flex tw-flex-row tw-items-center tw-text-nowrap">Member Limit Reached
                                                                <OverlayTrigger 
                                                                        placement="top"
                                                                        overlay={<Tooltip className="custom-tooltip">Members that have been fully attempted with no successful outcome.</Tooltip>}
                                                                    >
                                                                        <i className='bx bx-info-circle ms-1'></i>
                                                                    </OverlayTrigger>
                                                                </h5>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="col-3">
                                                        <div className="row">
                                                            <div className="col">
                                                                <h1 className="card-title1">{statsValue.onboardLaterCount}</h1>
                                                                <h5 className="card-text1">Onboard later
                                                                <OverlayTrigger 
                                                                        placement="top"
                                                                        overlay={<Tooltip className="custom-tooltip">Members interested in the program but will onboard later.</Tooltip>}
                                                                    >
                                                                        <i className='bx bx-info-circle ms-1'></i>
                                                                    </OverlayTrigger>
                                                                </h5>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-auto px-1" >
                                <div className={`card bg-grey`} style={{ borderRadius: '14px', border: '2px solid #1279be', height: "90px" }}>
                                    <div className="card-body p-2 d-flex align-items-center">
                                        <div className="row">
                                            <div className="col">
                                                <h1 className="card-title1">{statsValue.totalOnboardCount}</h1>
                                                <h5 className="card-text1">Total Onboarded
                                                <OverlayTrigger 
                                                    placement="top"
                                                    overlay={<Tooltip className="custom-tooltip">Combined total of members onboarded by both RCM and agents.</Tooltip>}
                                                    >
                                                    <i className='bx bx-info-circle ms-1'></i>
                                                </OverlayTrigger>
                                                </h5>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className={`card bg-grey mt-1`} style={{ borderRadius: '14px', border: 'none' }}>
                                        <div className="card-body p-2">
                                            <div className="row">
                                                <div className="col">
                                                    <h1 className="card-title1">{parseInt(statsValue.telehealthConfirmed) + parseInt(statsValue.telehealthCompleted)}</h1>
                                                    <h5 className="card-text1">Telehealth Confirmations
                                                    <OverlayTrigger 
                                                        placement="top"
                                                        overlay={<Tooltip className="custom-tooltip">Total number of telehealth consultations that are successfully confirmed or completed.</Tooltip>}
                                                    >
                                                        <i className='bx bx-info-circle ms-1'></i>
                                                    </OverlayTrigger>
                                                    </h5>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                        </div>
                    </div>
                </div>  
            </div>

                    <div className="row align-items-center mt-4 mb-2">
                        <div className="col"><h1 className='main-title'>Agent Performance</h1></div>
                    </div>



                    <div className="row">

                       <div className="col-md-4 mb-3">
                            <div className="dashboard-graph-box mb-3">
                                <h1 className='dashboard-graph-title '>
                                    <span>Calls Made
                                    <OverlayTrigger placement="top" overlay={renderMCI7Tooltip}>
                                        <i className='bx bx-info-circle ms-2'></i>
                                    </OverlayTrigger></span> 
                                    <span className="dashboard-graph-number">{totalCallMadeCount}</span>
                                </h1>

                                <CallsMadedBar startDate={startDate} endDate={endDate} agentId={stateAgentId} requestFrom="admin" parentCallback={handleCallMadeCallback} />
                            </div>
                        </div>

                        <div className="col-md-4 mb-3">
                            <div className="dashboard-graph-box mb-3">
                                <h1 className='dashboard-graph-title '>
                                    <span>Members Reached
                                    <OverlayTrigger placement="top" overlay={renderMCI6Tooltip}>
                                        <i className='bx bx-info-circle ms-2'></i>
                                    </OverlayTrigger></span> 
                                    <span className="dashboard-graph-number">{totalReachedCount}</span>
                                </h1>

                                <MembersReachedBar startDate={startDate} endDate={endDate} agentId={stateAgentId} requestFrom="admin" parentCallback={handleReachedCallback}/>
                            </div>
                        </div>

                        <div className="col-md-4 mb-3">
                            <div className="dashboard-graph-box mb-3">
                                <h1 className='dashboard-graph-title '>
                                    <span>Onboarded Members
                                    <OverlayTrigger placement="top" 
                                        overlay={<Tooltip className="custom-tooltip">Number of members added in the program</Tooltip>}>
                                        <i className='bx bx-info-circle ms-1'></i>
                                    </OverlayTrigger>
                                    </span> 
                                    <span className="dashboard-graph-number">{totalEnrollCount}</span>
                                </h1>

                                <MembersEnrolledBar startDate={startDate} endDate={endDate} agentId={stateAgentId} requestFrom="admin" parentCallback={handleEnrollCallback}/>
                            </div>
                        </div>


                        <div className="col-md-4 mb-3">
                            <div className="dashboard-graph-box mb-3">
                                <h1 className='dashboard-graph-title '>
                                    <span>Agents
                                    <OverlayTrigger placement="top" overlay={renderMCI9Tooltip}>
                                        <i className='bx bx-info-circle ms-2'></i>
                                    </OverlayTrigger></span> 
                                    <span className="dashboard-graph-number">{totalAgentsCount}</span>
                                </h1>

                                <AgentsBar startDate={startDate} endDate={endDate} agentId={stateAgentId} parentCallback={handleAgentCallback}/>
                            </div>
                        </div>

                        <div className="col-md-4 mb-3">
                            <div className="dashboard-graph-box mb-3">
                                <h1 className='dashboard-graph-title '>
                                    <span>Target Achievement 
                                    <OverlayTrigger placement="top" 
                                        overlay={<Tooltip className="custom-tooltip">Target Status achieved by agents</Tooltip>}>
                                        <i className='bx bx-info-circle ms-1'></i>
                                    </OverlayTrigger>
                                    </span> 
                                    {/* <span className="dashboard-graph-number">{totalTargetAchieved}%</span> */}
                                </h1>

                                <TargetAchievementBar startDate={startDate} endDate={endDate} agentId={stateAgentId} requestFrom="admin" parentCallback={handleTargetAchievedCallback}/>
                            </div>
                        </div>

                        <div className="col-md-4 mb-3">
                            <div className="dashboard-graph-box mb-3">
                                <h1 className='dashboard-graph-title '>
                                    <span>Call Conversion 
                                    <OverlayTrigger placement="top" 
                                        overlay={<Tooltip className="custom-tooltip">Bifurcation of call conversions</Tooltip>}>
                                        <i className='bx bx-info-circle ms-1'></i>
                                    </OverlayTrigger>
                                    </span> 
                                    {/* <span className="dashboard-graph-number">89%</span> */}
                                </h1>

                                <CallConversionDispositionBar startDate={startDate} endDate={endDate} agentId={stateAgentId} requestFrom="admin" />
                            </div>
                        </div>
                
                    </div>

                    <div className="member-engagement mb-3">

                    <div className="row text-center px-2">

                        <div className="col px-1" >
                            <div className={`card bg-grey`} style={{ borderRadius: '14px', border: 'none' }}>
                                <div className="card-body">
                                <div className="row">
                                    <div className="col">
                                        <h1 className="card-title1">{avgCallTime} <span>min</span></h1>
                                        <h5 className="card-text1">Average Call Duration
                                        <OverlayTrigger placement="top" overlay={renderMCI12Tooltip}>
                                            <i className='bx bx-info-circle ms-1'></i>
                                        </OverlayTrigger>
                                        </h5>
                                    </div>
                                </div>
                                </div>
                            </div>
                        </div>

                        <div className="col px-1" >
                            <div className={`card bg-grey`} style={{ borderRadius: '14px', border: 'none' }}>
                                <div className="card-body">
                                <div className="row">
                                    <div className="col">
                                        <h1 className="card-title1">{attemptCallTotal}</h1>
                                        <h5 className="card-text1">Attempt per member
                                        <OverlayTrigger placement="top" overlay={renderMCI13Tooltip}>
                                            <i className='bx bx-info-circle ms-1'></i>
                                        </OverlayTrigger>
                                        </h5>
                                    </div>
                                </div>
                                </div>
                            </div>
                        </div>

                        <div className="col px-1" >
                            <div className={`card bg-grey`} style={{ borderRadius: '14px', border: 'none' }}>
                                <div className="card-body">
                                <div className="row">
                                    <div className="col">
                                        <h1 className="card-title1">{firstCallConversionRate}</h1>
                                        <h5 className="card-text1">First Call Conversion Rate
                                        <OverlayTrigger placement="top" overlay={renderMCI14Tooltip}>
                                            <i className='bx bx-info-circle ms-1'></i>
                                        </OverlayTrigger>
                                        </h5>
                                    </div>
                                </div>
                                </div>
                            </div>
                        </div>

                        <div className="col px-1" >
                            <div className={`card bg-grey`} style={{ borderRadius: '14px', border: 'none' }}>
                                <div className="card-body">
                                <div className="row">
                                    <div className="col">
                                        <h1 className="card-title1">{memberSatisfaction}</h1>
                                        <h5 className="card-text1">Member Satisfaction
                                        <OverlayTrigger placement="top" overlay={renderMCI15Tooltip}>
                                            <i className='bx bx-info-circle ms-1'></i>
                                        </OverlayTrigger>
                                        </h5>
                                    </div>
                                </div>
                                </div>
                            </div>
                        </div>
                        <div className="col px-1" >
                            <div className={`card bg-grey`} style={{ borderRadius: '14px', border: 'none' }}>
                                <div className="card-body">
                                <div className="row">
                                    <div className="col">
                                        <h1 className="card-title1">{agentUtilizationRate}</h1>
                                        <h5 className="card-text1">Agent Utilization Rate
                                        <OverlayTrigger placement="top" overlay={renderMCI16Tooltip}>
                                            <i className='bx bx-info-circle ms-1'></i>
                                        </OverlayTrigger>
                                        </h5>
                                    </div>
                                </div>
                                </div>
                            </div>
                        </div>

                    </div>

                    </div>
                
                <div className="row">

                    <div className="col-md-4 mb-3">
                            <div className="dashboard-graph-box mb-3">
                                <h1 className='dashboard-graph-title '>
                                    <span>Data Quality
                                    <OverlayTrigger placement="top" 
                                        overlay={<Tooltip className="custom-tooltip">Measurement of data quality</Tooltip>}>
                                        <i className='bx bx-info-circle ms-1'></i>
                                    </OverlayTrigger>
                                    </span> 
                                </h1>

                                <DataQualityPie startDate={startDate} endDate={endDate} agentId={stateAgentId}  requestFrom="admin"/>
                            </div>
                        </div>

                        <div className="col-md-4 mb-3">
                            <div className="dashboard-graph-box mb-3">
                                <h1 className='dashboard-graph-title '>
                                    <span>Correct Data
                                    <OverlayTrigger placement="top" 
                                        overlay={<Tooltip className="custom-tooltip">Correct marked data disposition</Tooltip>}>
                                        <i className='bx bx-info-circle ms-1'></i>
                                    </OverlayTrigger>
                                    </span> 
                                </h1>

                                <MemberCorrectDataFunnelBar startDate={startDate} endDate={endDate} agentId={stateAgentId}  requestFrom="admin"/>
                            </div>
                        </div>

                        <div className="col-md-4 mb-3">
                            <div className="dashboard-graph-box mb-3">
                                <h1 className='dashboard-graph-title '>
                                    <span>Incorrect Data
                                    <OverlayTrigger placement="top" 
                                        overlay={<Tooltip className="custom-tooltip">Incorrect marked data disposition</Tooltip>}>
                                        <i className='bx bx-info-circle ms-1'></i>
                                    </OverlayTrigger>
                                    </span> 
                                </h1>

                                <MemberIncorrectDataFunnelBar startDate={startDate} endDate={endDate} agentId={stateAgentId}  requestFrom="admin"/>
                            </div>
                        </div>

                        {/* <div className="col-md-4 mb-3">
                            <div className="dashboard-graph-box mb-3">
                                <h1 className='dashboard-graph-title '>
                                    <span>Member Conversion funnel
                                    <OverlayTrigger placement="top" overlay={renderMCI18Tooltip}>
                                        <i className='bx bx-info-circle ms-2'></i>
                                    </OverlayTrigger></span> 
                                </h1>

                                <MemberConversionFunnelBar startDate={startDate} endDate={endDate} agentId={stateAgentId} memberReached = {totalReachedCount} memberEnrolled={totalEnrollCount} parentCallback={handleAgentMemberCallback}/>
                            </div>
                        </div> */}
                        <div className="col-md-4 mb-3">
                            <div className="dashboard-graph-box mb-3">
                                <h1 className='dashboard-graph-title '>
                                    <span>WhatsApp Sent
                                    <OverlayTrigger placement="top" overlay={<Tooltip className="custom-tooltip">WhatsApp sent by agent</Tooltip>}>
                                        <i className='bx bx-info-circle ms-2'></i>
                                    </OverlayTrigger></span> 
                                    <span className="dashboard-graph-number">{statsValueWhatsApp.whatsAppSentCount}</span>
                                </h1>

                                {loading ? (
                                    <></>
                                    ) : (
                                        seriesGraph.whatsAppSent.dataValue ? (
                                            <MembesAccrossPathwayMonthsBar dataMonth={seriesGraph.whatsAppSent} />
                                        ) : (
                                            <div className="emty-column">
                                                <h5 className="emty-title-graph">There are no data to display</h5>
                                            </div>
                                        )
                                )}
                            </div>
                        </div>

                        {/* <div className="col-md-4 mb-3">
                            <div className="dashboard-graph-box mb-3">
                                <h1 className='dashboard-graph-title '>
                                    <span>WhatsApp Delivered
                                    <OverlayTrigger placement="top" overlay={<Tooltip className="custom-tooltip">WhatsApp Delivered</Tooltip>}>
                                        <i className='bx bx-info-circle ms-2'></i>
                                    </OverlayTrigger></span> 
                                    <span className="dashboard-graph-number">{statsValueWhatsApp.whatsAppDeliveredCount}</span>
                                </h1>

                                {loading ? (
                                    <></>
                                    ) : (
                                        seriesGraph.whatsAppDelivered.dataValue ? (
                                            <MembesAccrossPathwayMonthsBar dataMonth={seriesGraph.whatsAppDelivered} />
                                        ) : (
                                            <div className="emty-column">
                                                <h5 className="emty-title-graph">There are no data to display</h5>
                                            </div>
                                        )
                                )}
                            </div>
                        </div>

                        <div className="col-md-4 mb-3">
                            <div className="dashboard-graph-box mb-3">
                                <h1 className='dashboard-graph-title '>
                                    <span>WhatsApp Read
                                    <OverlayTrigger placement="top" 
                                        overlay={<Tooltip className="custom-tooltip">WhatsApp Read by members</Tooltip>}>
                                        <i className='bx bx-info-circle ms-1'></i>
                                    </OverlayTrigger>
                                    </span> 
                                    <span className="dashboard-graph-number">{statsValueWhatsApp.whatsAppReadCount}</span>
                                </h1>
                                {loading ? (
                                    <></>
                                    ) : (
                                        seriesGraph.whatsAppRead.dataValue ? (
                                            <MembesAccrossPathwayMonthsBar dataMonth={seriesGraph.whatsAppRead} />
                                        ) : (
                                            <div className="emty-column">
                                                <h5 className="emty-title-graph">There are no data to display</h5>
                                            </div>
                                        )
                                )}
                            </div>
                        </div> */}

                        <div className="col-md-4 mb-3">
                            <div className="dashboard-graph-box mb-3">
                                <h1 className='dashboard-graph-title '>
                                    <span>Age Group Conversion
                                    <OverlayTrigger placement="top" overlay={renderMCI19Tooltip}>
                                        <i className='bx bx-info-circle ms-2'></i>
                                    </OverlayTrigger></span> 
                                </h1>

                                <AgeGroupConversionBar startDate={startDate} endDate={endDate} agentId={stateAgentId} />
                            </div>
                        </div>

                        <div className="col-md-4 mb-3">
                            <div className="dashboard-graph-box mb-3">
                                <h1 className='dashboard-graph-title '>
                                    <span>Call Status 
                                    <OverlayTrigger placement="top" overlay={renderMCI11Tooltip}>
                                        <i className='bx bx-info-circle ms-2'></i>
                                    </OverlayTrigger></span> 
                                </h1>
                                <CallDispositionBar startDate={startDate} endDate={endDate} agentId={stateAgentId} requestFrom="admin"/>
                            </div>
                        </div>
                </div>
                <div className="row align-items-center mt-4 mb-2">
                        <div className="col"><h1 className='main-title'>Telehealth Consultations</h1></div>
                    </div>
                    <div className="member-engagement mb-3">
                        <div className="row text-center px-2">
                            <div className="col-2 px-1" >
                                <div className={`card bg-grey`} style={{ borderRadius: '14px', border: '2px solid #1279be' }}>
                                    <div className="card-body">
                                    <div className="row">
                                        <div className="col">
                                            <h1 className="card-title1">{statsValue.telehealthBooking}</h1>
                                            <h5 className="card-text1">Telehealth Actions
                                            <OverlayTrigger placement="top" overlay={renderMCI17Tooltip}>
                                                <i className='bx bx-info-circle ms-1'></i>
                                            </OverlayTrigger>
                                            </h5>
                                        </div>
                                    </div>
                                    </div>
                                </div>
                            </div>
                            

                            <div className="col-6" >
                                    <div className="row">
                                    <div className="col-12 px-1" >
                                        <div className={`card bg-grey`} style={{ borderRadius: '14px', border: 'none', height:"100%",border: '2px solid #1279be', height: "90px" }}>
                                        <div className="card-body tw-px-2 tw-py-0 d-flex align-items-center">
                                        <div className="row" style={{ "width": "100%" }}>
                                    <div className="col border-end">
                                    <div className="row">
                                        <div className="col">
                                            <h1 className="card-title1">{statsValue.telehealthConfirmed}</h1>
                                            <h5 className="card-text1  tw-flex tw-flex-row tw-items-center">Confirmed
                                            <OverlayTrigger placement="top" overlay={renderMCI23Tooltip}>
                                                <i className='bx bx-info-circle ms-1 -tw-mb-1'></i>
                                            </OverlayTrigger>
                                            </h5>
                                        </div>
                                    </div>
                                </div>
                                    <div className="col border-end">
                                    <div className="row">
                                        <div className="col">
                                            <h1 className="card-title1">{statsValue.telehealthCompleted}</h1>
                                            <h5 className="card-text1  tw-flex tw-flex-row tw-items-center">Completed
                                            <OverlayTrigger placement="top" overlay={renderMCI24Tooltip}>
                                                <i className='bx bx-info-circle ms-1 -tw-mb-1'></i>
                                            </OverlayTrigger>
                                            </h5>
                                        </div>
                                    </div>
                                    </div>
                                    <div className="col">
                                    <div className="row">
                                        <div className="col">
                                            <h1 className="card-title1">{statsValue.telehealthCancelled}</h1>
                                            <h5 className="card-text1 tw-flex tw-flex-row tw-items-center">Cancelled
                                            <OverlayTrigger placement="top" overlay={renderMCI20Tooltip}>
                                                <i className='bx bx-info-circle ms-1 -tw-mb-1'></i>
                                            </OverlayTrigger>
                                            </h5>
                                        </div>
                                    </div>
                                    </div>
                                    
                                                </div>
                                        </div>
                                    </div>
                                </div>
                                </div>
                            </div>

                            <div className="col-2 px-1">
                            <div className={`card bg-grey`} style={{ borderRadius: '14px', border: '2px solid #1279be' }}>
                                    <div className="card-body">
                                    <div className="row">
                                        <div className="col">
                                            <h1 className="card-title1">{statsValue.telehealthReschedule}</h1>
                                            <h5 className="card-text1  tw-flex tw-flex-row tw-items-center">Reschedule
                                            <OverlayTrigger placement="top" overlay={renderMCI21Tooltip}>
                                                <i className='bx bx-info-circle ms-1 -tw-mb-1'></i>
                                            </OverlayTrigger>
                                            </h5>
                                        </div>
                                    </div>
                                    </div>
                                    </div>
                                    </div>

                            <div className="col-2 px-1" >
                                <div className={`card bg-grey`} style={{ borderRadius: '14px', border: '2px solid #1279be' }}>
                                    <div className="card-body">
                                    <div className="row">
                                        <div className="col">
                                            <h1 className="card-title1">{statsValue.telehealthOnboarded}</h1>
                                            <h5 className="card-text1">Members Onboarded
                                            <OverlayTrigger placement="top" overlay={renderMCI22Tooltip}>
                                                <i className='bx bx-info-circle ms-1'></i>
                                            </OverlayTrigger>
                                            </h5>
                                        </div>
                                    </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-6 mb-3">
                            <div className="dashboard-graph-box mb-3">
                                <h1 className='dashboard-graph-title '>
                                    <span>Telehealth Booking Status
                                    <OverlayTrigger placement="top" 
                                        overlay={<Tooltip className="custom-tooltip">Visual representation of the status of confirmed, completed, canceled, or rescheduled telehealth appointments.</Tooltip>}>
                                        <i className='bx bx-info-circle ms-1'></i>
                                    </OverlayTrigger>
                                    </span> 
                                </h1>
                                {
                                    seriesGraphNew.telehealthPie ? (
                                        <MemberPathwayStatusPie series={seriesGraphNew.telehealthPie} />
                                    ) : (
                                        <div className="emty-pie">
                                            <h5 className="emty-title-graph">There are no data to display</h5>
                                        </div>
                                    )
                                }
                            </div>
                        </div>
                        <div className="col-md-6 mb-3">
                            <div className="dashboard-graph-box mb-3">
                                <h1 className='dashboard-graph-title '>
                                    <span>Daily Telehealth Booking
                                    <OverlayTrigger placement="top" 
                                        overlay={<Tooltip className="custom-tooltip">Number of telehealth bookings made for the selected day.</Tooltip>}>
                                        <i className='bx bx-info-circle ms-1'></i>
                                    </OverlayTrigger>
                                    </span> 
                                </h1>
                                {
                                    seriesGraphNew.telehealthColumn.dataValue ? (
                                        <MembesAccrossPathwayMonthsBar dataMonth={seriesGraphNew.telehealthColumn} />
                                    ) : (
                                        <div className="emty-column">
                                            <h5 className="emty-title-graph">There are no data to display</h5>
                                        </div>
                                    )
                                }
                            </div>
                        </div>
                    </div>
                
            </div>

            

            </section>
        </>
    );
}

export default ContentCallHistory;
